const errorCatch = (error, message) => {
    if (error && error.response && error.response.data) {
        return error.response.data
    }

    return {
        status: false,
        message: message || 'Ocorreu um problema interno no servidor.'
    }
}

export default errorCatch
