import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'
import querystring from 'querystringify'

import './../../security/interceptors'

const all = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/send/all?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar as mensagens')
        })
}

const send = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/send/find?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a mensagem')
        })
}

const received = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/received/find?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a mensagem')
        })
}

const message = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/received/message?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a mensagem.')
        })
}

const status = (id) => {
    return api.get(`/messages/send/status/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os status')
        })
}

const scheduled = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/scheduled?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a contagem de agendamentos')
        })
}

const inProcess = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/in-process?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar as mensagens em processo')
        })
}

const setWaitSend = (arrayIds) => {
    return api.get(`/messages/set-wait-send?array_ids=${arrayIds}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível ser a mensagem para aguardando envio')
        })
}

const single = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/single?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a single mensagem')
        })
}

const byStatus = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/status?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os status.')
        })
}

const byMinute = () => {
    return api.get('/messages/total-per-minute')
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os totais por minuto.')
        })
}

const byHour = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/total-per-hour?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os totais por hora.')
        })
}

const top10 = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/send/top10?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível fazer a lista de top10')
        })
}

const search = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/send/search?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível fazer a busca de mensagens')
        })
}

const blockPredictive = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/block?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a contagem de agendamentos')
        })
}

const liberateMessage = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/block/liberate?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a contagem de agendamentos')
        })
}

export default {
    all,
    send,
    received,
    status,
    scheduled,
    inProcess,
    setWaitSend,
    single,
    byStatus,
    byMinute,
    byHour,
    message,
    top10,
    search,
    blockPredictive,
    liberateMessage
}
