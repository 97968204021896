
import store from './store'

import { createRouter, createWebHashHistory } from 'vue-router'

// Importação das rotas
import RouteAuth from './routes/auth'
import RouteDashboard from './routes/dashboard'
import RouteCampaigns from './routes/campaigns'
import RouteCompanies from './routes/companies'
import RouteFinances from './routes/finances'
import RouteImports from './routes/imports'
import RouteMessages from './routes/messages'
import RouteUsers from './routes/users'
import RouteReports from './routes/reports'
import RouteErrors from './routes/errors'


// Montamos um array geral com os arrays das rotas
let arrayRoutes = [
    ...RouteAuth,
    ...RouteDashboard,
    ...RouteCampaigns,
    ...RouteCompanies,
    ...RouteMessages,
    ...RouteFinances,
    ...RouteImports,
    ...RouteUsers,
    ...RouteReports,
    ...RouteErrors
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: arrayRoutes
})

/**
 * Devemos checar aqui se o usuário vai ter acesso a determinada rota
 * caso contrário devemos mandar ele de volta ao dashboard ou ao
 * login caso não tiver logado
 */

router.beforeEach((to, from, next) => {
    if (!store.state.authenticated && to.meta.auth) {
        router.push({ name: 'auth.login' })
    }

    if (to.meta.permition && !to.meta.permition.includes(store.state.user.permition)) {
        router.push({ name: 'app.error.permission' })
    }

    next()
})


export default router;
