<template>
    <div class="chart-month box-shadow" v-loading="loading">
        <div class="date-filter">
            <el-row align="middle">
                <el-col :xs="24" :lg="6" class="box-mobile">
                    <label>Selecione mês/ano</label>
                    <select v-model="month">
                        <option v-for="(item, key) in 12" :key="key" :value="`${('00' + item).slice(-2)}`">{{ ('00' + item).slice(-2) }}</option>
                    </select>
                    <select v-model="year">
                        <option v-for="(item, key) in years" :key="key" :value="item">{{ item }}</option>
                    </select>
                    <button type="button" class="ui-btn-primary" @click="loadChart()">Ok</button>
                </el-col>
                <el-col :xs="24" :lg="6" class="box-mobile">
                    <label v-if="company && company.custom_type == 'R'">Revenda</label>
                    <div class="sends">
                        <h2>{{ formatTotal(total_send) }} Envios</h2>
                    </div>
                    <div class="receiveds">
                        <h2>{{ formatTotal(total_received) }} Respostas</h2>
                    </div>
                </el-col>
                <el-col :xs="24" :lg="6" class="resale box-mobile" v-if="company && company.custom_type == 'R'">
                    <label>Clientes</label>
                    <div class="sends">
                        <h2>{{ formatTotal(total_resale_send) }} Envios</h2>
                    </div>
                    <div class="receiveds">
                        <h2>{{ formatTotal(total_resale_received) }} Respostas</h2>
                    </div>
                </el-col>
                <el-col :xs="24" :lg="6" class="btn-client-resale box-mobile" v-if="company && company.custom_type == 'R'">
                    <label>Exibir gráfico</label>
                    <button class="mr-1" v-bind:class="{ 'ui-btn-active': type === 'first' }" type="button" @click="setChart('first')">Revenda</button>
                    <button v-bind:class="{ 'ui-btn-active': type === 'second' }" type="button" @click="setChart('second')">Clientes</button>
                </el-col>
            </el-row>
        </div>
        <GChart
            v-if="(!loading && chartData.length > 0)"
            type="AreaChart"
            :data="chartData"
            :options="chartOptions"
        />
    </div>
</template>

<script setup>

    import { ref, reactive, computed, onMounted, getCurrentInstance } from 'vue'
    import CompaniesServices from './../services/companies'
    import DashboardServices from './../services/companies/dashboard'
    import { GChart } from 'vue-google-charts'
    import moment from 'moment'
    import { useRoute } from 'vue-router'
    import { isMobile } from './../mixins'

    const app = getCurrentInstance()
    const emitter = app.appContext.config.globalProperties.emitter
    const route = useRoute()

    const type = ref('first')
    const loading = ref(false)
    const arrayData = ref([])
    const arrayDataClient = ref([])
    const arrayDataResale = ref([])
    const arrayOptions = reactive({
        pieHole: 0.4,
        height: 450,
        legend: 'none',
        tooltip: {
            textStyle: { fontSize: 11 }
        },
        series: [
            { color: "#19c462" },
            { color: "#36a2eb" }
        ],
        chartArea: {
            width: '86%',
            height: '86%'
        },
        pointSize: 3,
        hAxis: {
            gridlines: { count: 0, color: 'transparent' },
            textStyle : {
                fontSize: 9
            }
        },
        vAxis: {
            gridlines: { count: 10 },
            textStyle : {
                fontSize: 10
            }
        }
    })
    const month = ref('')
    const year = ref('')
    const years = ref([])
    const total = ref(0)
    const total_send = ref(0)
    const total_received = ref(0)
    const total_resale_send = ref(0)
    const total_resale_received = ref(0)
    const company = ref({})

    const chartData = computed(() => {
        return arrayData.value
    })

    const chartOptions = computed(() => {
        return arrayOptions
    })

    onMounted(async () => {

        let date = new Date()
        month.value = ('0' + (date.getMonth() + 1)).slice(-2)
        year.value = date.getFullYear()

        for (let index = 2020; index <= year.value; index++) {
            years.value.push(index)
        }

        if (isMobile()) {
            arrayOptions.height = 200
        }

        await loadCompany()
        await loadChart()

    })

    const loadChart = async () => {
        await loadDataClient()
        await loadDataResale()
        await setChart('first')
    }

    const loadCompany = async () => {
        try {
            emitter.emit('loading', { status: true, message: 'Carregando os dados da empresa...' })

            let response = await CompaniesServices.show(route.params.id)

            if (response && response.status) {
                company.value = response.results
                return
            }
            throw Error(response.message)
        } catch (e) {
            emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar os dados da empresa.' })
        } finally {
            emitter.emit('loading', { status: false })
        }
    }

    const loadDataClient = async () => {
        try {
            loading.value = true
            arrayDataClient.value = []
            total_send.value = 0
            total_received.value = 0

            let query = {
                month: month.value,
                year: year.value
            }

            let response = await DashboardServices.byChart(route.params.id, query)

            let daysInMonth = moment().set('month', month.value - 1).set('year', year.value).daysInMonth()

            if (response && response.status) {

                arrayDataClient.value = []

                let arrayHeader = ['Dia', 'Total Envio', 'Total Respostas']
                let arrayBody = []
                
                for (let index = 1; index <= daysInMonth; index++) {
                    let day = ('0' + index).slice(-2)
                    let find = response.results.find((i) => {
                        return i.yearmonth === `${year.value}-${month.value}-${day}`
                    })
                    arrayBody.push([day, find ? parseInt(find.total_send) : 0, find ? parseInt(find.total_received) : 0])
                    total_send.value += find ? parseInt(find.total_send) : 0
                    total_received.value += find ? parseInt(find.total_received) : 0
                }
                
                arrayDataClient.value.push(arrayHeader, ...arrayBody)
                
            }
        } catch (e) {
            emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar os dados para o gráfico.' })
        } finally {
            loading.value = false
        }
    }

    const loadDataResale = async () => {
        try {
            loading.value = true
            arrayDataResale.value = []
            total_resale_send.value = 0
            total_resale_received.value = 0

            let query = {
                month: month.value,
                year: year.value,
                resale: true
            }

            let response = await DashboardServices.byChart(route.params.id, query)

            let daysInMonth = moment().set('month', month.value - 1).set('year', year.value).daysInMonth()

            if (response && response.status) {

                arrayDataResale.value = []

                let arrayHeader = ['Dia', 'Total Envio', 'Total Respostas']
                let arrayBody = []
                
                for (let index = 1; index <= daysInMonth; index++) {
                    let day = ('0' + index).slice(-2)
                    let find = response.results.find((i) => {
                        return i.yearmonth === `${year.value}-${month.value}-${day}`
                    })
                    arrayBody.push([day, find ? parseInt(find.total_send) : 0, find ? parseInt(find.total_received) : 0])
                    total_resale_send.value += find ? parseInt(find.total_send) : 0
                    total_resale_received.value += find ? parseInt(find.total_received) : 0
                }
                
                arrayDataResale.value.push(arrayHeader, ...arrayBody)

            }
        } catch (e) {
            emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar os dados para o gráfico.' })
        } finally {
            loading.value = false
        }
    }

    const setChart = async (param) => {
        loading.value = true
        type.value = param
        if (type.value === 'first') {
            arrayData.value = arrayDataClient.value
        } else {
            arrayData.value = arrayDataResale.value
        }
        arrayOptions.value = arrayOptions.value
        loading.value = false
    }

    const formatTotal = (total) => {
        return total.toLocaleString('pt-BR')
    }



    // import CompaniesServices from './../services/companies'
    // import DashboardServices from './../services/companies/dashboard'
    // import { GChart } from 'vue-google-charts'
    // import moment from 'moment'

    // export default {
    //     name: 'component-campanie-month',

    //     components: {
    //         GChart,
    //     },

    //     computed: {
    //         chartData () {
    //             return this.arrayData
    //         },
    //         chartOptions () {
    //             return this.arrayOptions
    //         }
    //     },

    //     data () {
    //         return {
    //             type: 'first',
    //             loading: false,
    //             arrayData: [],
    //             arrayDataClient : [],
    //             arrayDataResale: [],
    //             arrayOptions: {
    //                 pieHole: 0.4,
    //                 height: 450,
    //                 legend: 'none',
    //                 tooltip: {
    //                     textStyle: { fontSize: 11 }
    //                 },
    //                 series: [
    //                     { color: "#19c462" },
    //                     { color: "#36a2eb" }
    //                 ],
    //                 chartArea: {
    //                     width: '86%',
    //                     height: '86%'
    //                 },
    //                 pointSize: 3,
    //                 hAxis: {
    //                     gridlines: { count: 0, color: 'transparent' },
    //                     textStyle : {
    //                         fontSize: 9
    //                     }
    //                 },
    //                 vAxis: {
    //                     gridlines: { count: 10 },
    //                     textStyle : {
    //                         fontSize: 10
    //                     }
    //                 }
    //             },
    //             month: '',
    //             year: '',
    //             years: [],
    //             total_send: 0,
    //             total_received: 0,
    //             total_resale_send: 0,
    //             total_resale_received: 0,
    //             company: null
    //         }
    //     },

    //     /**
    //      * Acessamos o load para carregar a lista de grupos assim que
    //      * o componente é montado
    //      */
    //     async created () {
    //         let date = new Date()
    //         this.day = date.getDate()
    //         this.month = ('0' + (date.getMonth() + 1)).slice(-2)
    //         this.year = date.getFullYear()

    //         for (let index = 2020; index <= this.year; index++) {
    //             this.years.push(index)
    //         }

    //         if (this.isMobile()) {
    //             this.arrayOptions.height = 200
    //         }

    //         await this.loadCompany()
    //         await this.loadChart()
    //     },

    //     methods: {

    //         async loadChart () {
    //             await this.loadDataClient()
    //             await this.loadDataResale()
    //             await this.setChart('first')
    //         },

    //         async loadCompany () {
    //             try {
    //                 this.emitter.emit('loading', { status: true, message: 'Carregando os dados da empresa...' })

    //                 let result = await CompaniesServices.show(this.$route.params.id)

    //                 if (result && result.status) {
    //                     this.company = result.results
    //                     return
    //                 }
    //                 throw Error(result.message)
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar os dados da empresa.' })
    //             } finally {
    //                 this.emitter.emit('loading', { status: false })
    //             }
    //         },

    //         async loadDataClient () {
    //             try {
    //                 this.loading = true
    //                 this.arrayDataClient = []
    //                 this.total_send = 0
    //                 this.total_received = 0

    //                 let query = {
    //                     month: this.month,
    //                     year: this.year
    //                 }

    //                 let result = await DashboardServices.byChart(this.$route.params.id, query)

    //                 let daysInMonth = moment().set('month', this.month - 1).set('year', this.year).daysInMonth()

    //                 if (result && result.status) {

    //                     this.arrayDataClient = []

    //                     let arrayHeader = ['Dia', 'Total Envio', 'Total Respostas']
    //                     let arrayBody = []
                        
    //                     for (let index = 1; index <= daysInMonth; index++) {
    //                         let day = ('0' + index).slice(-2)
    //                         let find = result.results.find((i) => {
    //                             return i.yearmonth === `${this.year}-${this.month}-${day}`
    //                         })
    //                         arrayBody.push([day, find ? parseInt(find.total_send) : 0, find ? parseInt(find.total_received) : 0])
    //                         this.total_send += find ? parseInt(find.total_send) : 0
    //                         this.total_received += find ? parseInt(find.total_received) : 0
    //                     }
                        
    //                     this.arrayDataClient.push(arrayHeader, ...arrayBody)
                        
    //                 }
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar os dados para o gráfico.' })
    //             } finally {
    //                 this.loading = false
    //             }
    //         },

    //         async loadDataResale () {
    //             try {
    //                 this.loading = true
    //                 this.arrayDataResale = []
    //                 this.total_resale_send = 0
    //                 this.total_resale_received = 0

    //                 let query = {
    //                     month: this.month,
    //                     year: this.year,
    //                     resale: true
    //                 }

    //                 let result = await DashboardServices.byChart(this.$route.params.id, query)

    //                 let daysInMonth = moment().set('month', this.month - 1).set('year', this.year).daysInMonth()

    //                 if (result && result.status) {

    //                     this.arrayDataResale = []

    //                     let arrayHeader = ['Dia', 'Total Envio', 'Total Respostas']
    //                     let arrayBody = []
                        
    //                     for (let index = 1; index <= daysInMonth; index++) {
    //                         let day = ('0' + index).slice(-2)
    //                         let find = result.results.find((i) => {
    //                             return i.yearmonth === `${this.year}-${this.month}-${day}`
    //                         })
    //                         arrayBody.push([day, find ? parseInt(find.total_send) : 0, find ? parseInt(find.total_received) : 0])
    //                         this.total_resale_send += find ? parseInt(find.total_send) : 0
    //                         this.total_resale_received += find ? parseInt(find.total_received) : 0
    //                     }
                        
    //                     this.arrayDataResale.push(arrayHeader, ...arrayBody)

    //                 }
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar os dados para o gráfico.' })
    //             } finally {
    //                 this.loading = false
    //             }
    //         },

    //         async setChart (type) {
    //             this.loading = true
    //             this.type = type
    //             if (type === 'first') {
    //                 this.arrayData = this.arrayDataClient
    //             } else {
    //                 this.arrayData = this.arrayDataResale
    //             }
    //             this.arrayOptions = this.arrayOptions
    //             this.loading = false
    //         },

    //         formatTotal (total) {
    //             return total.toLocaleString('pt-BR')
    //         }
    //     }
    // }
</script>

<style lang="stylus" scoped>

    @import './../assets/stylus/variables.styl'

    .chart-month
        background-color #fff
        padding 20px

        .sends h2
            color #19c462
        .receiveds h2
            color #36a2eb

        .btn-client-resale
            button
                background-color #ccc
        
        .ui-btn-active
            background-color #19c462 !important

        .box-mobile
            padding-bottom 20px

        .date-filter
            padding 15px
            margin-bottom 20px
            label
                font-size 14px
                font-weight bold
                padding 0
                display block
            select
                margin-right 6px
                width 80px


    @media screen and (min-width: 768px)

        .chart-month

            .box-mobile
                padding-bottom 0


</style>
