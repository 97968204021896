<template>
    <div v-if="item">
        <div class="ui-campaign-card box-shadow p-2 mb-2" v-bind:class="{ 'schedule': isSchedule, 'deleted': isDeleted }" >
            <el-row align="middle">
                <el-col :xs="24" :lg="14">
                    <div>
                        <span class="origin" v-bind:class="{ 'app': item.origin === 'app' || item.origin === 'app#', 'api': item.origin === 'single' || item.origin === 'v2' || item.origin === 'v1' }">{{ item.origin === 'v1' || item.origin === 'v2' || item.origin === 'single' ? 'API ' + item.origin : 'WEB ' + item.origin.replace('app', '') }}</span>
                        <span class="origin channel_code">{{ item.channel_code }}</span>
                        <span class="origin type" v-if="item.services.type === 'voice'">Mensagem de Voz</span>
                    </div>
                    <h3>
                        <router-link :to="{ name : 'app.campaigns.show', params: { id: item.id }  }">
                            {{ item.title }}
                        </router-link>
                    </h3>
                    <span class="message">{{ item.message }}</span>
                    <el-alert type="error" :closable="false" v-if="item.message.length > 160">
                        <strong>Atenção</strong>, mensagem com <strong>{{ item.message.length }}</strong> caracteres. <strong>Custo de {{ Math.ceil(item.message.length / 150) }} mensagens</strong>
                    </el-alert>
                    <h5>
                        <router-link :to="{ name : 'app.companies.refresh', params: { id: item.company.id }  }">
                            {{ item.company.nick_name }}
                        </router-link>
                    </h5>
                    <div class="date">
                        <span>{{ textDate() }}</span>
                        <span v-if="item.scheluded_to">Data de agendamento {{ moment(item.scheluded_to).format('DD/MM/YYYY HH:mm') }}</span>
                        <span>Criado em {{ moment(item.created_at).format('DD/MM/YYYY HH:mm') }}</span>
                    </div>
                </el-col>
                <el-col :xs="24" :lg="4">
                    <div class="total-contacts">
                        <h3>{{ item.totalContacts }} <span class="text">contatos</span></h3>
                    </div>
                </el-col>
                <el-col :xs="24" :lg="6" class="align-buttons">
                    <div v-if="item.deleted_at == null">
                        <button class="ui-btn-success mr-1" @click="forceProvider()" v-if="isForceProvider() && (isAdmin() || isSuper())">
                            Forçar Envio
                        </button>
                        <button @click="isVisible()" class="ui-btn-default">
                            Estatísticas
                        </button>
                    </div>
                </el-col>
            </el-row>
            <div v-if="statistics.visible" class="block-statistics">
                <ui-campaign-card-statistics :id="item.id" :channel_code="item.channel_code"></ui-campaign-card-statistics>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { ref, reactive, onMounted } from 'vue'
    import axios from 'axios'
    import moment from 'moment'
    import momentTimezone from 'moment-timezone'

    const props = defineProps({
        item: { type: Object }
    })

    const isSchedule = ref(false)
    const isDeleted = ref(false)
    const statistics = reactive({
        visible: false
    })
    const provider = reactive({
        loading: false
    })

    onMounted(() => {
        textDate()
    })

    const textDate = () => {

        if (props.item.deleted_at !== null) {
            isDeleted.value = true
            return `Campanha cancelada em ${moment(props.item.deleted_at).format('DD/MM/YYYY HH:mm')}`
        }

        if (props.item.sented_at === null && props.item.scheluded_to === null) {
            return ''
        }

        if (wasSent()) {
            if (props.item.sented_at) {
                return `Enviado em ${momentTimezone.utc(props.item.sented_at).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')}`
            }
            return ''
        }
        
        isSchedule.value = true
        return `Agendado para ${moment(props.item.scheluded_to).format('DD/MM/YYYY HH:mm')}`
    }

    const dateFormat = (date, time = false) => {
        if (time) {
            return momentTimezone.utc(date).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')
        }
        return momentTimezone.utc(date).tz('America/Sao_Paulo').format('DD/MM/YYYY')
    }

    const wasSent = () => {
        if (props.item.sented_at !== null) {
            return true
        }
        return false
    }

    const isVisible = () => {
        statistics.visible = !statistics.visible
    }

    const isForceProvider = () => {
        if (props.item.sented_at === null && props.item.scheluded_to === null) {
            return true
        }
        return false
    }

    const forceProvider = async () => {
        try {
            provider.loading = true
            emitter.emit('loading', { status: true, message: 'Forçando o envio da campanha pelo provider.' })

            let subdomain = ''
            let providerName = props.item.channel_code.substring(0, 3)
            switch (providerName) {
                case 'MOV':
                    subdomain = 'sinch'
                    providerName = 'SMSStart'
                    break
                case 'IFB':
                    subdomain = 'infobip'
                    providerName = 'SMSStart'
                    break
                case 'TTS':
                    if (props.item.channel_code === 'TTSIFB') {
                        subdomain = 'infobip-tts'
                        providerName = 'TTSStart'
                    } else if (props.item.channel_code === 'TTSZEN') {
                        subdomain = 'zenvia-tts'
                        providerName = 'TTSStart'
                    }
                    break
                case 'ZEN':
                    subdomain = 'zenvia'
                    providerName = 'SMSStart'
                    break
            }

            axios.post(`https://${subdomain}.plataformadesms.com.br/${providerName}?campaignId=${props.item.id}`).then(response => {
                    return true
                })
                .catch((e) => {
                    return false
                })

            emitter.emit('notify', { type: 'success', message: 'Provider foi reenviado com sucesso.' })
        } catch (e) {
            emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível reenviar o provider.' })
        } finally {
            provider.loading = false
            emitter.emit('loading', { status: false })
        }
    }

</script>


<style lang="stylus" scoped>

    @import './../assets/stylus/variables.styl'

    .ui-campaign-card
        font-size 12px
        line-height 18px
        border-radius 2px
        padding 15px
        background #fff
        border-left 3px #fff solid

        .total-contacts
            margin 5px 0 10px 0

        &.schedule
            border-left 3px $orange solid
            .date
                color $orange !important
            .total-contacts
                color $orange !important
                h3
                    color $orange !important

        &.deleted
            border-left 3px $red-light solid
            opacity 0.4
            .date
                color $red-light !important
            .total-contacts
                color $red-light !important
                h3
                    color $red-light !important

        a
            text-decoration none
            color $grey

        .origin
            background $grey
            padding 2px 7px
            color #ffffff
            font-size 10px
            text-transform uppercase
            top 0
            &.app
                background $primary
            &.api
                background $secondary
            &.channel_code
                background $grey
                margin-left 5px
            &.type
                background $orange
                margin-left 5px

        .message
            font-size 11px
            color $primary
            display block
            background lighten($primary, 95%)
            border 1px lighten($primary, 65%) dotted
            padding 3px
            margin 4px 0
            white-space pre-line

        .date
            color $grey
            font-size 11px
            span
                display block
                &.scheduled
                    color $orange

        .align-buttons
            text-align center

    @media screen and (min-width: 768px)

        .ui-campaign-card

            .align-buttons
                text-align left

            .total-contacts
                text-align center
                margin 0

                .text
                    display block
                    font-size 11px
                    font-weight normal
                    color $grey


</style>