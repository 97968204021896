import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'
import querystring from 'querystringify'

import './../../security/interceptors'

const findAll = () => {
    return api.get('/companies/consumption')
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os consumos')
        })
}

const findBySeller = () => {
    return api.get('/companies/consumption/byseller')
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os consumos por vendedor')
        })
}

const findBy = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/companies/consumption/by?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os consumos por vendedor')
        })
}

export default {
    findAll,
    findBySeller,
    findBy
}
