import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'
import querystring from 'querystringify'

import './../../security/interceptors'

const total = (id, objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/companies/${id}/dashboard?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a empresa')
        })
}

const serviceUsage = (id, days) => {
    days = days || 10
    return api.get(`/companies/${id}/usage?period=${days}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os dados de uso.')
        })
}

const byChart = (id, objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/companies/${id}/by-chart?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os dados de uso.')
        })
}

export default {
    total,
    serviceUsage,
    byChart
}
