import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'
import querystring from 'querystringify'

import './../../security/interceptors.js'

const show = (id) => {
    return api.get(`/companies/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a empresa')
        })
}

const findAll = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/companies?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar as empresas')
        })
}

const all = () => {
    return api.get(`/companies/all`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar todas as empresas')
        })
}

const balance = (id) => {
    return api.get(`/companies/${id}/balance`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar o saldo')
        })
}

const status = (id, status) => {
    return api.get(`/companies/${id}/status?status=${status}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar o saldo')
        })
}

const configApi = (id, api_use, api_view_key) => {
    return api.get(`/companies/${id}/api?api_use=${api_use}&api_view_key=${api_view_key}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível salvar a configuração da API')
        })
}

const channelUpdateKey = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.put(`/companies/channels/key?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível salvar a chave.')
        })
}

const channelUpdateChannel = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.put(`/companies/channels/channel?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível salvar a chave.')
        })
}


const channelUpdateTarif = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.put(`/companies/channels/tarif?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível salvar a tarifa.')
        })
}

export default {
    show,
    balance,
    status,
    configApi,
    findAll,
    all,
    channelUpdateKey,
    channelUpdateChannel,
    channelUpdateTarif
}
