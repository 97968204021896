import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'
import querystring from 'querystringify'

import './../../security/interceptors'

const all = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/finances/billings-config?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a lista de configurações cobrança')
        })
}

const show = (id) => {
    return api.get(`/finances/billings-config/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a configurações cobrança')
        })
}

const destroy = (id) => {
    return api.delete(`/finances/billings-config/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a configurações cobrança')
        })
}

const storeOrUpdate = (data) => {
    return api.post('/finances/billings-config/store-or-update', data)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível cadastrar a configurações cobrança')
        })
}

const search = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/finances/billings-config/search?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível encontrar o cliente')
        })
}

export default {
    all,
    show,
    destroy,
    storeOrUpdate,
    search
}
