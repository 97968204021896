
import store from './store'
import moment from 'moment'
import momentTimezone from 'moment-timezone'

export const isMobile = () => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}


export default {
    methods: {

        isMobile,

        // Formata uma date ou datetime
        dateFormat (value, datetime) {
            return moment(value).format(datetime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')
        },

        // Formatando a date conforme o valor passado
        getDateFormat (value, format) {
            return moment(value).format(format)
        },

        getDateFormatUTC (date) {
            return momentTimezone.utc(date).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')
        },

        getDiffDates (dateStart, dateEnd) {
            dateStart = moment(dateStart)
            dateEnd = moment(dateEnd)
            return dateEnd.diff(dateStart, 'minutes')
        },

        getDiffDatesSeconds (dateStart, dateEnd) {
            dateStart = moment(dateStart)
            dateEnd = moment(dateEnd)
            return dateEnd.diff(dateStart, 'seconds')
        },

        // Formata um float para o padrão pt-BR junto com o cifrão R$
        priceFormat (value, decimal = 2) {
            return new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: decimal,
                style: 'currency',
                currency: 'BRL'
            }).format(value)
        },

        // Transformar um valor R$ 1,50 para float 1.50
        treatmentPriceToFloat (value) {
            return value.toString().replace(/[^0-9|,]/g, '').replace(',', '.')
        },

        // Transforma um número de telefone para tipo number (47) 99191-1212 para 47991911212
        treatmentPhoneToNumber (value) {
            return value.replace(/[^0-9]/g, '')
        },

        // Transforma uma data pt-BR para o formato americano 31/12/2018 para 2018-12-31
        treatmentDateStringToDate (value) {
            return value.split('/').reverse().join('-')
        },

        // Remover caracteres especiais
        strSlug (strToReplace) {
            let strSChar = 'áàâãäåaaaÁÂÃÄÅAAAÀéèêëeeeeeEEEÉEEÈìíîïìiiiÌÍÎÏÌIIIóôõöoooòÒÓÔÕÖOOOùúûüuuuuÙÚÛÜUUUUçÇñÑýÝ\\´`'
            let strNoSChars = 'aaaaaaaaaAAAAAAAAAeeeeeeeeeEEEEEEEiiiiiiiiIIIIIIIIooooooooOOOOOOOOuuuuuuuuUUUUUUUUcCnNyY/  '
            let newStr = ''

            for (let i = 0; i < strToReplace.length; i++) {
                if (strSChar.indexOf(strToReplace.charAt(i)) !== -1) {
                    newStr += strNoSChars.substr(strSChar.search(strToReplace.substr(i, 1)), 1)
                } else {
                    newStr += strToReplace.substr(i, 1)
                }
            }

            return newStr.replace(/[^a-zA-Z0-9/.:;,+*_$%=\-<>@~^#?!()|[]{}]+/g, '')
        },

        isSuper () {
            if (store.state.user) {
                return store.state.user.permition === 'S' || false
            }
            return false
        },

        isAdmin () {
            if (store.state.user) {
                return store.state.user.permition === 'A' || false
            }
            return false
        },

        isComercial () {
            if (store.state.user) {
                return store.state.user.permition === 'C' || false
            }
            return false
        },

        isUser () {
            if (store.state.user) {
                return store.state.user.permition === 'U' || false
            }
            return false
        },

        getServiceName (id) {
            switch (id) {
            case 1: return 'SMS'
            case 2: return 'Flash'
            case 3: return '3'
            case 4: return 'TTS'
            case 5: return 'SMS sem resposta'
            case 6: return 'Flash sem resposta'
            }
            return id
        },

        getSignatureType (type) {
            switch (type) {
            case 'C':
                return 'Pós Pago'
            case 'P':
                return 'Pré Pago'
            case 'T':
                return 'Teste'
            }
            return '-'
        },

        getPersonType (type) {
            switch (type) {
            case 'PF':
                return 'P. Física'
            case 'PJ':
                return 'P. Jurídica'
            }
            return '-'
        },

        getCustomType (type) {
            switch (type) {
            case 'R':
                return 'Revenda'
            case 'C':
                return 'Cliente'
            }
            return '-'
        },

        getPermition (permition) {
            switch (permition) {
            case 'S':
                return 'Super'
            case 'A':
                return 'Admin'
            case 'C':
                return 'Comercial'
            case 'U':
                return 'Usuário'
            }
            return '-'
        },

        // Método retorna a descrição do mês informado
        getMonth (month, abrev = false) {
            let long = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
            let abr = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
            if (abrev) {
                return abr[month - 1]
            }
            return long[month - 1]
        },

        // Add Days
        addDays (value, days) {
            return moment(value).add(days, 'days')
        },

        getMonthFormated () {
            let today = new Date()
            let month = this.getMonth(today.getMonth() + 1)
            let year = today.getMonth() === 1 ? today.getFullYear() - 1 : today.getFullYear()
            return month.concat('/', year)
        },

        countChars (message) {
            let count = message.length
            if (message.indexOf('#NOME#') !== -1 || message.indexOf('#nome#') !== -1) {
                count = count + 6
            }

            if (message.indexOf('#VAR1#') !== -1 || message.indexOf('#var1#') !== -1) {
                count = count + 6
            }

            if (message.indexOf('#VAR2#') !== -1 || message.indexOf('#var2#') !== -1) {
                count = count + 6
            }

            if (message.indexOf('#VAR3#') !== -1 || message.indexOf('#var3#') !== -1) {
                count = count + 6
            }

            var allChars = message.split('')
            var specialCharts = ['|', '^', '€', '{', '}', '[', ']', '~']

            allChars.forEach(item => {
                if (specialCharts.includes(item)) {
                    count = count + 1
                }
            })

            return count
        },

        countCredits (message) {
            let total = this.countChars(message)
            if (total > 160) {
                return Math.ceil(total / 153)
            }
            return Math.ceil(total / 160)
        }
    }
}
