import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'
import querystring from 'querystringify'

import './../../security/interceptors'

const show = (id) => {
    return api.get(`/campaigns/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a campanha')
        })
}

const status = (id, objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/campaigns/${id}/status?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível alterar os status.')
        })
}

const contacts = (id, objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/campaigns/${id}/contacts?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os contatos')
        })
}

const findAll = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/campaigns?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a campanha')
        })
}

const findPending = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/campaigns/pending?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar as campanhas pendentes')
        })
}

const destroy = (id) => {
    return api.delete(`/campaigns/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível excluir a campanha')
        })
}

const store = (data) => {
    let formData = new FormData()

    formData.append('id_client', data.id_client)
    formData.append('title', data.title)
    formData.append('id_channel', data.id_channel)
    formData.append('scheluded_to', data.scheluded_to)
    formData.append('id_service', data.id_service)
    formData.append('file', data.file)

    return api.post('/campaigns/store', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível cadastrar a campanha')
        })
}

const scheduled = () => {
    return api.get('/campaigns/scheduled')
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar as campanhas agendadas e já enviadas')
        })
}

const pending2Minutes = () => {
    return api.get('/campaigns/pending-2-minutes')
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar as campanhas pendentes a mais de 2 minutos')
        })
}

const findLastMinutes = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/campaigns/last-minutes?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar as campanhas pendentes')
        })
}

const channel = (id, objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/campaigns/${id}/channel?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível atualizar o canal')
        })
}

const closing = (id) => {
    return api.get(`/campaigns/${id}/close`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível fechar a campanha')
        })
}

const unblock = (id, objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/campaigns/${id}/unblock?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível desbloquear as mensagens')
        })
}

const update = (id, objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/campaigns/${id}/update?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível atualizar a campanha')
        })
}

export default {
    show,
    status,
    contacts,
    findAll,
    findPending,
    store,
    destroy,
    scheduled,
    pending2Minutes,
    findLastMinutes,
    channel,
    closing,
    unblock,
    update
}
