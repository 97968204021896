import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'
import querystring from 'querystringify'

import './../../security/interceptors'

const findAll = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/companies/notifications?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar as empresas')
        })
}

const store = (data) => {
    return api.post('/companies/notifications', data)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível salvar a notificação.')
        })
}

const update = (data) => {
    return api.put('/companies/notifications', data)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível atualizar a notificação.')
        })
}

const destroy = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.delete(`/companies/notifications?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível atualizar a notificação.')
        })
}

const show = (id) => {
    return api.get(`/companies/notifications/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a notificação.')
        })
}

const search = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/companies/notifications/search?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar as empresas')
        })
}

export default {
    findAll,
    store,
    update,
    destroy,
    show,
    search
}
