<template>
    <div class="box-shadow" v-loading="loading">
        <div class="box-title">
            Envios do dia <u>{{ dateFormat(filter.date, false) }}</u> por hora
        </div>
        <el-scrollbar :height="`${isMobile() ? '210px' : '310px' }`">
            <GChart
                v-if="(!loading && chartData.length > 0)"
                type="AreaChart"
                :data="chartData"
                :options="chartOptions"
            />
        </el-scrollbar>
        <div class="box-footer box-filter">
            <input type="date" v-model="filter.date" /> <button type="button" class="ui-btn-default" @click="load()">Ok</button>
        </div>
    </div>
</template>

<script setup>

    import { ref, reactive, computed, getCurrentInstance, onMounted, onUnmounted } from 'vue'
    import MessagesServices from './../services/messages'
    import { isMobile } from './../mixins'
    import SetInterval from 'set-interval'
    import { GChart } from 'vue-google-charts'

    const app = getCurrentInstance()
    const emitter = app.appContext.config.globalProperties.emitter

    const loading = ref(false)
    const arrayData = ref([])
    const arrayOptions = reactive({
        pieHole: 0.4,
        height: 300,
        legend: 'none',
        tooltip: {
            textStyle: { fontSize: 11 }
        },
        chartArea: {
            width: '86%',
            height: '86%',
        },
        pointSize: 3,
        hAxis: {
            gridlines: { count: 0, color: 'transparent' },
            textStyle : {
                fontSize: 9
            }
        },
        vAxis: {
            gridlines: { count: 10 },
            textStyle : {
                fontSize: 10
            }
        }
    })
    const filter = reactive({
        date: new Date().toISOString().slice(0, 10)
    })
    const data = reactive({
        list: []
    })


    const chartData = computed(() => {
        return arrayData.value
    })

    const chartOptions = computed(() => {
        return arrayOptions
    })

    onMounted(async () => {
        if (isMobile()){
            arrayOptions.height = 200
        }

        await load()
        
        SetInterval.start(() => {
            load()
        }, 120000, 'byhours')
    })

    onUnmounted(() => {
        SetInterval.clear('byhours')
    })

    const load = async () => {
        try {
            loading.value = true
            let response = await MessagesServices.byHour({ date: filter.date })
            if (response && response.status) {
                data.list = response.results
                processChart()
            }
        } catch (e) {
            emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de totais.' })
        } finally {
            loading.value = false
        }
    }


    const processChart = () => {

        arrayData.value = []

        let arrayHeader = ['Hora', 'Total', { role: 'style' }]

        let arrayBody = data.list.map((item) => {
            return [item.time.substring(0,2), parseInt(item.total || 0 ), '#82bb40']
        })

        arrayData.value.push(arrayHeader, ...arrayBody)

    }



    // import MessagesServices from './../services/messages'
    // import SetInterval from 'set-interval'
    // import { GChart } from 'vue-google-charts'

    // export default {
    //     name: 'component-total-per-hour',

    //     components: {
    //         GChart,
    //     },

    //     data () {
    //         return {
    //             loading: false,
    //             arrayData: [],
    //             arrayOptions: {
    //                 pieHole: 0.4,
    //                 height: 300,
    //                 legend: 'none',
    //                 tooltip: {
    //                     textStyle: { fontSize: 11 }
    //                 },
    //                 chartArea: {
    //                     width: '86%',
    //                     height: '86%',
    //                 },
    //                 pointSize: 3,
    //                 hAxis: {
    //                     gridlines: { count: 0, color: 'transparent' },
    //                     textStyle : {
    //                         fontSize: 9
    //                     }
    //                 },
    //                 vAxis: {
    //                     gridlines: { count: 10 },
    //                     textStyle : {
    //                         fontSize: 10
    //                     }
    //                 }
    //             },
    //             filter: {
    //                 date: new Date().toISOString().slice(0, 10)
    //             },
    //             data: {
    //                 list: []
    //             }
    //         }
    //     },

    //     computed: {
    //         chartData () {
    //             return this.arrayData
    //         },
    //         chartOptions () {
    //             return this.arrayOptions
    //         }
    //     },


    //     async created () {
            
    //         if (this.isMobile()){
    //             this.arrayOptions.height = 200
    //         }

    //         this.load()
            
    //         SetInterval.start(() => {
    //             this.load()
    //         }, 120000, 'byhours')
    //     },

    //     unmounted () {
    //         SetInterval.clear('byhours')
    //         console.log('Unmounted byhours')
    //     },

    //     methods: {
    //         async load () {
    //             try {
    //                 this.loading = true
    //                 let result = await MessagesServices.byHour({ date: this.filter.date })
    //                 if (result && result.status) {
    //                     this.data.list = result.results
    //                     this.processChart()
    //                 }
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de totais.' })
    //             } finally {
    //                 this.loading = false
    //             }
    //         },
    //         processChart () {

    //             this.arrayData = []

    //             let arrayHeader = ['Hora', 'Total', { role: 'style' }]

    //             let arrayBody = this.data.list.map((item) => {
    //                 return [item.time.substring(0,2), parseInt(item.total || 0 ), '#82bb40']
    //             })

    //             this.arrayData.push(arrayHeader, ...arrayBody)

    //         }
    //     }
    // }
</script>

<style lang="stylus" scoped>

    @import './../assets/stylus/variables.styl'

    .box-title
        color #82bb40 !important

</style>
