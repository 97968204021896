
export default [
    {
        path: '/app/imports',
        name: 'app.imports',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-imports" */ './../views/App/Imports/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    }
]
