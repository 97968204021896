import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'

export default (username, password) => {
    return api.post('/login', { username: username, password: password })
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível fazer o login. Usuário ou senha inválido.')
        })
}
