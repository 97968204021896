<template>
    <el-row align="middle" class="statistics" v-loading="loading">
        <el-col :xs="24" :lg="20">
            <el-row v-if="!loading && countTotal() > 0" class="numbers">
                <el-col :xs="24" :lg="6" v-for="(item,key) in results" :key="key">
                    <span class="dot" :style="{ 'background-color': item.color }"></span>
                    <strong>{{ item.count }}</strong> {{ item.name }}
                </el-col>
                <el-col :xs="24" :lg="6">
                    <span class="dot dot-primary"></span> <strong>{{ countTotal() }}</strong> Total
                </el-col>
            </el-row>
            <div v-if="countTotal() == 0">
                Nâo existe dados no momento para carregar as estatísticas.
            </div>
        </el-col>
        <el-col :xs="24" :lg="4" class="text-center">
            <button class="ui-btn-success" @click="forceProvider()" v-if="is_force_provider && (isAdmin() || isSuper())">
                Forçar Envio
            </button>
            <button class="ml-1 ui-btn-default" @click="load()">
                Atualizar
            </button>
            <button class="ml-1 ui-btn-update" @click="updateStatusCampaign()" v-if="(isSuper() || isAdmin()) && hasSend">
                Update
            </button>
        </el-col>
    </el-row>
</template>

<script setup>
    import { ref, computed, onMounted, getCurrentInstance } from 'vue'

    import axios from 'axios'
    import MessagesServices from './../services/messages'
    import StatusServices from './../services/status/providers'

    const app = getCurrentInstance()
    const emitter = app.appContext.config.globalProperties.emitter

    const props = defineProps({
        id: String,
        channel_code: String,
        is_force_provider: Boolean,
        sented_at: String,
        closed_at: String,
        scheluded_to: String
    })

    const loading = ref(false)
    const results = ref([])

    const hasSend = computed(() => {
        let find = results.value.find((item) => {
            return item.name === 'Enviado'
        })
        if (find) {
            return true
        }
        return false
    })

    onMounted(() => {
        load()
    })

    const load = async () => {
        try {
            loading.value = true

            let result = await MessagesServices.status(props.id)

            if (result && result.status) {
                results.value = result.results
                return
            }

            throw new Error()
        } catch (e) {
            const app = getCurrentInstance()
            const emitter = app.appContext.config.globalProperties.emitter

            emitter.emit('notify', { type: 'error', message: 'Ocorreu um erro ao carregar as estatísticas da campanha.' })
        } finally {
            loading.value = false
        }
    }

    const forceProvider = async () => {
        try {

            emitter.emit('loading', { status: true, message: 'Forçando o envio da campanha pelo provider.' })
            
            let subdomain = ''
            let providerName = props.channel_code.substring(0, 3)

            switch (providerName) {
                case 'MOV':
                    subdomain = 'movile'
                    providerName = 'SMSStart'
                    break
                case 'IFB':
                    subdomain = 'infobip'
                    providerName = 'SMSStart'
                    break
                case 'TTS':
                    if (props.channel_code === 'TTSIFB') {
                        subdomain = 'infobip'
                        providerName = 'TTSStart'
                    } else if (props.channel_code === 'TTSZEN') {
                        subdomain = 'zenvia'
                        providerName = 'TTSStart'
                    }
                    break
                case 'SIG':
                    subdomain = 'sigavox'
                    providerName = 'SMSStart'
                    break
                case 'ZEN':
                    subdomain = 'zenvia'
                    providerName = 'SMSStart'
                    break
            }

            axios.post(`https://${subdomain}.plataformadesms.com.br/${providerName}?campaignId=${props.id}`).then(response => {
                    return true
                })
                .catch((e) => {
                    return false
                })

            emitter.emit('notify', { type: 'success', message: 'Provider foi reenviado com sucesso.' })

        } catch (e) {
            emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível reenviar o provider.' })
        } finally {
            emitter.emit('loading', { status: false })
        }
    }

    const updateStatusCampaign = async () => {
        try {

            emitter.emit('loading', { status: true, message: 'Aguarde... Buscando os status da campanha e atualizando...' })
            
            let result
            let data = {
                campaign_id: props.id
            }

            let providerName = props.channel_code.substring(0, 3)

            switch (providerName) {
                case 'MOV':
                    result = await StatusServices.movile(data)
                    break
                case 'IFB':
                    result = await StatusServices.infobip(data)
                    break
                case 'ZEN':
                    result = await StatusServices.zenvia(data)
                    break
            }

            if (result && result.status) {
                load()
            }

            emitter.emit('loading', { status: false })

        } catch (e) {
            emitter.emit('notify', { type: 'error', message: 'Ocorreu um erro ao atualizar o status da campanha.' })
        } finally {
            loading.value = false
        }
        
    }

    const countTotal = () => {
        let total = 0
        results.value.forEach((item) => {
            total += item.count
        })
        return total
    }

    



    // export default {
    //     name: 'component-campaign-card-statistics',
    //     props: ['id', 'channel_code', 'is_force_provider', 'sented_at', 'closed_at', 'scheluded_to'],

    //     data () {
    //         return {
    //             loading: false,
    //             results: []
    //         }
    //     },

    //     computed: {

    //         // Pega os 15 primeiros caracteres do primeiro nome do usuário
    //         hasSend () {
    //             let find = this.results.find((item) => {
    //                 return item.name === 'Enviado'
    //             })
    //             if (find) {
    //                 return true
    //             }
    //             return false
    //         }
    //     },

    //     mounted () {
    //         this.load()
    //     },

    //     methods: {

    //         // Método responsavel por carregar as estatisticas de status de uma determinada campanha
    //         async load () {
    //             try {
    //                 this.loading = true

    //                 let result = await MessagesServices.status(this.id)

    //                 if (result && result.status) {
    //                     this.results = result.results
    //                     return
    //                 }

    //                 throw new Error()
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: 'Não foi possível atualizar os status' })
    //             } finally {
    //                 this.loading = false
    //             }
    //         },

    //         // Faz a contagem utilizando reduce no resultado
    //         countTotal () {
    //             if (this.results && this.results.length === 0) {
    //                 return 0
    //             }
    //             return this.results.reduce((total, item) => {
    //                 return total + item.count
    //             }, 0)
    //         },

    //         // Forçar o provider a enviar a campanha caso tenha falhado em algum momento
    //         async forceProvider () {
    //             try {
    //                 this.emitter.emit('loading', { status: true, message: 'Forçando o envio da campanha pelo provider.' })
    //                 let subdomain = ''
    //                 let providerName = this.channel_code.substring(0, 3)
    //                 switch (providerName) {
    //                 case 'MOV':
    //                     subdomain = 'movile'
    //                     providerName = 'SMSStart'
    //                     break
    //                 case 'IFB':
    //                     subdomain = 'infobip'
    //                     providerName = 'SMSStart'
    //                     break
    //                 case 'TTS':
    //                     if (this.channel_code === 'TTSIFB') {
    //                         subdomain = 'infobip'
    //                         providerName = 'TTSStart'
    //                     } else if (this.channel_code === 'TTSZEN') {
    //                         subdomain = 'zenvia'
    //                         providerName = 'TTSStart'
    //                     }
    //                     break
    //                 case 'SIG':
    //                     subdomain = 'sigavox'
    //                     providerName = 'SMSStart'
    //                     break
    //                 case 'ZEN':
    //                     subdomain = 'zenvia'
    //                     providerName = 'SMSStart'
    //                     break
    //                 }
    //                 axios.post(`https://${subdomain}.plataformadesms.com.br/${providerName}?campaignId=${this.id}`).then(response => {
    //                     return true
    //                 })
    //                     .catch((e) => {
    //                         return false
    //                     })
    //                 this.emitter.emit('notify', { type: 'success', message: 'Provider foi reenviado com sucesso.' })
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível reenviar o provider.' })
    //             } finally {
    //                 this.emitter.emit('loading', { status: false })
    //             }
    //         },

    //         async updateStatusCampaign () {
    //             this.emitter.emit('loading', { status: true, message: 'Aguarde... Buscando os status da campanha e atualizando...' })
    //             let result
    //             let data = {
    //                 campaign_id: this.id
    //             }
    //             let providerName = this.channel_code.substring(0, 3)
    //             switch (providerName) {
    //             case 'MOV':
    //                 result = await StatusServices.movile(data)
    //                 break
    //             case 'IFB':
    //                 result = await StatusServices.infobip(data)
    //                 break
    //             case 'ZEN':
    //                 result = await StatusServices.zenvia(data)
    //                 break
    //             }
    //             if (result && result.status) {
    //                 this.load()
    //             }
    //             this.emitter.emit('loading', { status: false })
    //         }
    //     }
    // }
</script>

<style lang="stylus" scoped>

    @import './../assets/stylus/variables.styl'

    .statistics
        background lighten($grey, 90%)
        padding 15px
        border 1px #f5f5f5 dotted
        color #777
        font-size 12px

        .dot
            width 10px
            height 10px
            background rgb(85, 139, 47)
            display inline-block
            width 10px
            height 10px
            background #90a4ae
            border-radius 50%

        .dot-primary
            background-color $primary

        .numbers
            strong
                padding-left 4px

</style>
