
export default [
    {
        path: '/app/messages',
        name: 'app.messages',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-messages-dashboard" */ './../views/App/Messages/Dashboard.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/messages/send',
        name: 'app.messages.send',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-messages-send-search" */ './../views/App/Messages/SendSearch.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/messages/all',
        name: 'app.messages.all',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-messages-send" */ './../views/App/Messages/Send.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/messages/received',
        name: 'app.messages.received',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-messages-received" */ './../views/App/Messages/Received.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/messages/block',
        name: 'app.messages.block',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-messages-block" */ './../views/App/Messages/Block.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    }
]
