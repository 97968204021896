<template>
    <div class="box-invoice box-shadow">
    
        <div class="alert-client-provider mb-2" v-if="options.client.id_provider && form.id_provider">
            <el-alert v-if="options.client.id_provider === form.id_provider" title="Perfeito!" description="Fornecedor e cliente selecionados correspondem." type="success" show-icon :closable="false" />
            <el-alert v-else title="Atenção!!!" description="Fornecedor e cliente selecionados NÃO correspondem." type="error" show-icon :closable="false" />
        </div>

        <div>

            <el-row :gutter="20">
                <el-col :xs="24" :lg="4" class="xs-mb-2">
                    <!-- Cliente -->
                    <label>Fatura</label>
                    <input v-model="form.number" disabled>
                </el-col>
                <el-col :xs="24" :lg="15" class="xs-mb-2">
                    <!-- Fornecedor -->
                    <label>Fornecedor</label>
                    <select v-model="form.id_provider" full-width>
                        <option v-for="(option, key) in options.providers" :key="key" :value="option.id">{{ option.name }}</option>
                    </select>
                </el-col>
                <!-- Data Faturamento -->
                <el-col :xs="24" :lg="5">
                    <label>Data Faturamento</label>
                    <input :value="dateFormat(form.today || form.created_at, false)" type="text" disabled>
                </el-col>
            </el-row>

            <!-- Cliente -->
            <div class="mt-2 mb-2">
                <label>Cliente (Nome Fantasia • Razão Social • CNPJ/CPF)</label>
                <select v-model="form.id_client" @change="setClient()">
                    <option v-for="(option, key) in options.clients" :key="key" :value="option.id">
                        {{option.nick_name}} • {{option.social_name}} • CNPJ/CPF: {{option.document_id}}
                    </option>
                </select>
            </div>

            <el-row :gutter="20">
                <el-col :xs="24" :lg="8" class="xs-mb-2">
                    <label>Vencimento</label>
                    <input type="date" v-model="form.dueDate_at">
                </el-col>
                <el-col :xs="24" :lg="8" class="xs-mb-2">
                    <label>Valor IBPT</label>
                    <input type="text" :value="priceFormat(form.ibptvalue, 2)" disabled>
                </el-col>
                <el-col :xs="24" :lg="8">
                    <label>Total</label>
                    <input type="text" :value="priceFormat(form.total, 2)" disabled>
                </el-col>
            </el-row>

            <div class="list-services mt-3" v-if="form.id_client">

                <button type="button" class="ui-btn-primary mb-1" @click="openDialog()">
                    Adicionar Serviço
                </button>

                <div v-if="isMobile()">
                    <el-card v-for="(item, key) in form_services.list" :key="key" class="mb-1 box-card">
                        <template #header>
                            <h3>{{ item.description }}</h3>
                        </template>
                        <div>
                            <strong>Quantidade:</strong> {{ item.quantity }}<br>
                            <strong>Valor Unit.:</strong> {{ priceFormat(item.unitvalue, 5) }}<br>
                            <strong>Total:</strong> {{ priceFormat(item.total, 5) }}<br>
                            <el-button color="#f44336" @click="removeItem(item.date)" class="mt-1">
                                <el-icon :size="18" class="mr-1"><DeleteFilled /></el-icon> Remover
                            </el-button>
                        </div>
                    </el-card>
                    <el-card v-if="form_services.list.length == 0">
                        Nenhum serviço selecionado.
                    </el-card>
                </div>
                
                <el-table v-else :data="form_services.list" empty-text="Nenhum serviço adicionado" class="box-shadow">
                    <el-table-column prop="description" label="Descrição" />
                    <el-table-column label="Remover" align="center" width="150">
                        <template #default="scope">
                            <a href="javascript:;" @click="removeItem(scope.row.date)" class="ui-icon-delete">
                                <el-icon :size="16"><DeleteFilled /></el-icon>
                            </a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="quantity" label="Quantidade" align="center" width="150"/>
                    <el-table-column label="Valor Unit." align="right" width="150">
                        <template #default="scope">
                            {{ priceFormat(scope.row.unitvalue, 5) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Total" align="right" width="150">
                        <template #default="scope">
                            {{ priceFormat(scope.row.total, 5) }}
                        </template>
                    </el-table-column>
                </el-table>

                <div class="text-right pt-1">
                    Total
                    <br><span class="price-total">{{ priceFormat(form.total,2) }}</span>
                </div>

                <br>

                <div class="mt-2 mb-2">
                    <label>Observações</label>
                    <textarea rows="5" v-model="form.notes"></textarea>
                </div>

                <!-- Salvar -->
                <button type="button" class="ui-btn-primary mr-1" @click="saveForm()" :disabled="form_services.list.length == 0">
                    Salvar
                </button>
                <router-link class="ui-link-no-underline" :to="{ name: 'app.finances.invoices' }">
                    <button type="button" class="ui-btn-none">Cancelar</button>
                </router-link>

            </div>

            <!-- Dialog -->
            <el-dialog title="Novo Serviço" :width="`${isMobile() ? '90%' : '800px'}`" v-model="dialog.visible">

                <div class="mb-2">
                    <label>Serviço</label>
                    <select label="Serviço" v-model="options.id_service" full-width @change="setService()">
                        <option v-for="(option, key) in options.channels" :key="key" :value="option.services.id">{{ option.services.name }}</option>
                    </select>
                </div>

                <div class="mb-2">
                    <label>Descrição</label>
                    <textarea rows="3" v-model="service.description"></textarea>
                </div>
                
                <el-row :gutter="20" align="bottom" v-if="dialog.visible">
                    <el-col :xs="24" :lg="6" class="xs-mb-2">
                        <label>Quantidade</label>
                        <input type="number" min="0" v-model="service.quantity" >
                    </el-col>
                    <el-col :xs="24" :lg="6">
                        <label>Valor Unit.</label>
                        <ui-money  v-model="service.unitvalue" :options="{ currency: 'BRL', precision: 5, hideCurrencySymbolOnFocus: false }" />
                    </el-col>
                    <el-col :xs="24" :lg="6" class="xs-mb-2">
                        <button class="ui-btn-primary" @click="service.total = service.quantity * service.unitvalue">Calcula</button>
                    </el-col>
                    <el-col :xs="24" :lg="6">
                        <label>Total</label>
                        <input :value="priceFormat(service.total, 5)" disabled />
                    </el-col>
                </el-row>

                <br>
                
                <button class="ui-btn-primary mr-1" @click="addItem()" :disabled="service.total == 0 || !service.description">Salvar</button>
                <button @click="closeDialog()" class="ui-btn-none">Cancelar</button>

            </el-dialog>

        </div>
        
    </div>
</template>

<script>
    import { mask } from 'vue-the-mask'
    
    import { VMoney } from 'v-money'
    import ChannelsServices from './../../services/commercial/channels-clients'
    import ClientsServices from './../../services/commercial/clients'
    import AppConfigServices from './../../services/commercial/appconfig'
    import InvoiceServices from './../../services/commercial/invoice'

    export default {
        name: 'component-forms-invoice',
        props: ['type'],

        directives: {
            mask,
            money: VMoney
        },


        data () {
            return {
                amount: 123.12,
                options: {
                    client: {},
                    clients: [],
                    channels: [],
                    config: {},
                    providers: [
                        { id: 'd8796bc8-d32f-4d23-ba1d-ad0ed3e9747c', name: 'Ziad' },
                        { id: '328021c3-1555-4f89-bd21-3dc66568304a', name: 'Neokemp' }
                    ],
                    id_provider: ''
                },
                moneyFormat: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$ ',
                    precision: 4
                },
                moneyFormatCredit: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'R$ ',
                    precision: 2
                },
                dialog: {
                    visible: false
                },
                form: {
                    calculate: true,
                    id: 0,
                    id_provider: '',
                    id_client: '',
                    today: new Date(),
                    dueDate_at: new Date(),
                    notes: '',
                    ibptperc: 0,
                    ibptvalue: 0,
                    total: 0,
                    details: []
                },
                form_services: {
                    loading: false,
                    list: []
                },
                service: {
                    date: new Date(),
                    description: '',
                    quantity: 0,
                    unitvalue: 0,
                    total: 0
                },
                fatura: {}
            }
        },


        async created () {
            await this.loadClients()
            await this.loadConfigs()
            if (this.type === 'edit') {
                await this.loadInvoice()
            }
        },


        methods: {

            async loadInvoice () {
                try {
                    this.emitter.emit('loading', { status: true, message: 'Carregando a fatura...' })
                    let result = await InvoiceServices.find(this.$route.params.id)

                    if (result && result.status) {
                        this.form = {
                            ...this.form,
                            ...result.results.invoice
                        }
                        this.form.today = this.form.created_at
                        this.form_services.list = result.results.invoice_detail.map((item) => {
                            return {
                                date: item.id,
                                description: item.description,
                                quantity: item.quantity,
                                unitvalue: item.unitvalue,
                                total: item.total
                            }
                        })
                        this.setClient()
                        this.fatura = result.results.result
                        return
                    }

                    throw Error(result.message)
                } catch (e) {
                    this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar a fatura.' })
                } finally {
                    this.emitter.emit('loading', { status: false })
                }
            },

            async loadClients () {
                try {
                    let result = await ClientsServices.invoice()

                    if (result && result.status) {
                        this.options.clients = result.results
                        return
                    }

                    throw Error(result.message)
                } catch (e) {
                    this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar os canais.' })
                }
            },

            async loadConfigs () {
                try {
                    let result = await AppConfigServices.all()

                    if (result && result.status) {
                        this.options.config = result.results
                        return
                    }
                    throw Error(result.message)
                } catch (e) {
                    this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar as configurações.' })
                }
            },

            async setClient () {
                this.options.client = this.options.clients.find(item => {
                    return item.id === this.form.id_client
                })

                if (this.type === 'create') {
                    this.form.dueDate_at = this.addDays(new Date(), this.options.client.billConfig.payment_days)
                } else {
                    this.form.dueDate_at = new Date(this.form.dueDate_at).toISOString().slice(0, 10)
                }

                let chs = await ChannelsServices.find(this.form.id_client)
                this.options.channels = chs.results
                this.setInvoiceNotes()
            },

            setInvoiceNotes () {
                let note = this.options.config.ibpt_notes.concat('\r\n\r\n', this.options.client.billConfig.invoice_notes)
                this.form.notes = note.replace('[IBPTVAL]', this.priceFormat(this.form.ibptvalue)).replace('[IBPT]', this.options.config.ibpt_percentual)
            },

            setService () {
                let ch = this.options.channels.find(item => {
                    return item.services.id === this.options.id_service
                })
                this.populateService(ch)
            },

            populateService (ch) {
                this.options.id_service = ch.services.id
                // this.service.description = ch.iteminvoicedescription ? ch.iteminvoicedescription.replace('[MONTH]', this.getMonthFormated()) : ''
                this.service.description = ch.iteminvoicedescription
                this.service.unitvalue = ch.ch1_tax
            },

            openDialog () {
                this.dialog.visible = true
                if (this.options.channels.length === 1) {
                    let ch = this.options.channels[0]
                    this.populateService(ch)
                } else {
                    this.options.id_service = null
                    this.service = {}
                }
            },

            closeDialog () {
                this.dialog.visible = false
                this.service = {}
            },

            setTotal () {
                this.form.total = this.form_services.list.reduce((total, item) => {
                    return total + (item.unitvalue * item.quantity)
                }, 0)
                this.form.ibptvalue = this.form.total * (this.options.config.ibpt_percentual / 100)
                this.form.ibptperc = this.options.config.ibpt_percentual
            },

            addItem () {
                let objeto = {
                    date: this.service.date,
                    description: this.service.description,
                    quantity: this.service.quantity,
                    unitvalue: this.service.unitvalue,
                    total: this.service.unitvalue * this.service.quantity
                }

                this.form_services.list.push(objeto)

                this.setTotal()
                this.setInvoiceNotes()

                this.service = {
                    date: new Date(),
                    description: '',
                    quantity: 0,
                    unitvalue: 0,
                    total: 0
                }
                this.options.id_service = null
                this.dialog.visible = false
            },

            removeItem (date) {
                this.form_services.list = this.form_services.list.filter(item => {
                    return item.date !== date
                })
                this.setTotal()
            },

            async saveForm () {
                try {
                    this.emitter.emit('loading', { status: true, message: 'Salvando o Fatura...' })
                    this.form.details = this.form_services.list
                    this.form.id_provider = this.form.id_provider
                    let result
                    if (this.type === 'edit') {
                        result = await InvoiceServices.update(this.form.id, this.form)
                    } else {
                        result = await InvoiceServices.store(this.form)
                    }
                    if (result && result.status) {
                        this.emitter.emit('notify', { type: 'success', message: result.message || 'A fatura foi salva com sucesso.' })
                        this.$router.push({ name: 'app.finances.invoices' })
                        return
                    } else if (result.code === 400 || result.code === 406) {
                        this.emitter.emit('notify', { type: 'error', message: result.message })
                        return
                    } else if (result.code === 422) {
                        for (const key in result.results.errors) {
                            if (result.results.errors.hasOwnProperty(key)) {
                                const element = result.results.errors[key]
                                this.emitter.emit('notify', { type: 'error', message: element.message })
                                return
                            }
                        }
                        return
                    } else if (result && !result.status && !result.message) {
                        for (const key in result) {
                            if (result.hasOwnProperty(key)) {
                                const element = result[key]
                                throw Error(element[0])
                            }
                        }
                        return
                    }
                    throw Error(result.message)
                } catch (e) {
                    this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível salvar o cliente.' })
                } finally {
                    this.emitter.emit('loading', { status: false })
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>

    @import './../../assets/stylus/variables.styl'

    .box-invoice
        padding 20px
        background #fff
        margin-top 20px

        .xs-mb-2
            margin-bottom 20px

        input, select, textarea
            width 100%

        textarea
            font-family Arial, Helvetica, sans-serif

        .alert-client-provider
            p
                font-size 16px
                padding 10px
                svg
                    margin-right 10px
            .success
                color $green
                background lighten($green, 50)
                border 1px lighten($green, 30) solid

            .error
                color $red
                background lighten($red, 30)
                border 1px lighten($red, 20) solid

        .ui-icon-delete
            color $red

        .price-total
            color $green
            font-size 16px
            font-weight 900

        .el-table
            font-size 12px

    @media screen and (min-width: 768px)

        .box-invoice
            .xs-mb-2
                margin-bottom 0



</style>
