
export default [
    {
        path: '/app/finances',
        name: 'app.finances',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-finances" */ './../views/App/Finances/Dashboard.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/finances/invoices',
        name: 'app.finances.invoices',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-finances-invoices" */ './../views/App/Finances/Invoice/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/finances/invoices/create',
        name: 'app.finances.invoices.create',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-finances-invoices-create" */ './../views/App/Finances/Invoice/Create.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/finances/invoices/edit/:id',
        name: 'app.finances.invoices.edit',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-finances-invoices-edit" */ './../views/App/Finances/Invoice/Edit.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/finances/ibpt',
        name: 'app.finances.ibpt',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-finances-ibpt" */ './../views/App/Finances/Ibpt/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/finances/ibpt/create',
        name: 'app.finances.ibpt.create',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-finances-ibpt-create" */ './../views/App/Finances/Ibpt/Create.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/finances/ibpt/edit/:id',
        name: 'app.finances.ibpt.edit',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-finances-ibpt-edit" */ './../views/App/Finances/Ibpt/Edit.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/finances/billings-config',
        name: 'app.finances.billingsconfig',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-finances-billings-config" */ './../views/App/Finances/BillingsConfig/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/finances/billings-config/create',
        name: 'app.finances.billingsconfig.create',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-finances-billings-config-create" */ './../views/App/Finances/BillingsConfig/Create.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/finances/billings-config/edit/:id',
        name: 'app.finances.billingsconfig.edit',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-finances-billings-config-edit" */ './../views/App/Finances/BillingsConfig/Edit.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    }
]
