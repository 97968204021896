<template>
    <input ref="inputRef" type="text" >
</template>
  
<script setup>

    import { useCurrencyInput } from 'vue-currency-input'

    const props = defineProps({
        modelValue: Number, // Vue 2: value
        options: Object
    })

    const { inputRef } = useCurrencyInput(props.options)


//   import { useCurrencyInput } from 'vue-currency-input'
  
//   export default {
//     name: 'component-money',
//     props: {
//       modelValue: Number, // Vue 2: value
//       options: Object
//     },
//     setup(props) {
//       const { inputRef } = useCurrencyInput(props.options)
  
//       return { inputRef }
//     }
//   }

</script>