<template>
    <div class="form-billings-config pt-2">
       
        <div class="ui-search box-shadow mb-3">
            <label>Buscar por Empresa</label>
            <input v-model="search.name" @keyup.enter="searchClient()" >
            <button class="ui-btn-primary" @click="searchClient()" :disabled="!search.name">Buscar</button>
        </div>

        <div class="ui-box-white box-shadow">

            <div class="mb-1">
                <label>Empresa (Nome Fantasia • Razão Social • Status • Documento)</label>
                <select v-model="form.company_Id">
                    <option v-for="(item,key) in clients.list" :key="key" :value="item.id">{{item.nick_name}} • {{item.social_name}} • {{item.status === 'A' ? 'Ativo' : 'Inativo'}} • CNPJ/CPF: {{item.document_id}}</option>
                </select>
            </div>

            <div class="mb-1">
                <label>Dias para vencimento</label>
                <input type="number" v-model="form.payment_days">
            </div>

            <div class="mb-1">
                <label>Descrição pagamento</label>
                <textarea v-model="form.invoice_notes" rows="3"></textarea>
                <span>* Dados do banco para depósito.</span>
            </div>

            <br>

            <button class="ui-btn-primary" @click="saveForm()" :disabled="!form.company_Id || !form.payment_days">
                {{ type === 'create' ? 'Cadastrar' : 'Atualizar' }}
            </button>

            <router-link class="ml-1" :to="{ name : 'app.finances.billingsconfig' }">
                <button class="ui-btn-none">Cancelar</button>
            </router-link>
        </div>

    </div>
</template>

<script>
    import BillingsServices from './../../services/finances/billingsconfig'

    export default {
        name: 'component-forms-billings-config',
        props: ['type'],

        
        data () {
            return {
                clients: {
                    list: []
                },
                search: {
                    name: '',
                    loading: false
                },
                form: {
                    company_Id: '',
                    invoice_type: 'F',
                    export_monthlyreport: true,
                    payment_days: 30,
                    invoice_notes: ''
                }
            }
        },

        async created () {
            if (this.type === 'edit') {
                this.load(this.$route.params.id)
            }
        },


        methods: {
            async load (id) {
                try {
                    this.emitter.emit('loading', { status: true, message: 'Aguarde... Estamos carregando os dados da configuração...' })

                    let result = await BillingsServices.show(id)

                    if (result && result.status) {
                        this.clients.list = [result.results.company]
                        this.form = result.results
                        this.emitter.emit('loading', { status: false })
                        return
                    }

                    throw Error(result.message)
                } catch (e) {
                    this.emitter.emit('loading', { status: false })
                    this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar as configurações.' })
                }
            },

            async searchClient () {
                try {
                    this.emitter.emit('loading', { status: true, message: 'Aguarde... Estamos buscando seus clientes...' })

                    this.clients.list = []
                    this.form.company_Id = ''

                    let result = await BillingsServices.search({ name: this.search.name })

                    if (result && result.status) {
                        this.clients.list = result.results
                        this.emitter.emit('loading', { status: false })
                        return
                    }

                    throw Error(result.message)
                } catch (e) {
                    this.emitter.emit('loading', { status: false })
                    this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar os clientes.' })
                }
            },

            async saveForm () {
                try {
                    this.emitter.emit('loading', { status: true, message: 'Aguarde enquanto salvamos os dados...' })

                    let result = await BillingsServices.storeOrUpdate(this.form)

                    if (result && result.status === 400) {
                        for (const key in result.errors) {
                            if (result.errors.hasOwnProperty(key)) {
                                const element = result.errors[key]
                                this.emitter.emit('notify', { type: 'error', message: element[0] })
                                return
                            }
                        }
                        return
                    } else if (result && result.status) {
                        this.emitter.emit('loading', { status: false })
                        this.emitter.emit('notify', { type: 'success', message: result.message || 'Os dados foram salvos com sucesso.' })
                        this.$router.push({ name: 'app.finances.billingsconfig' })
                        return
                    }

                    throw Error(result.message)
                } catch (e) {
                    this.emitter.emit('loading', { status: false })
                    this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível salvar os dados.' })
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>

    .ui-search
        input
            width 70%
            margin-right 10px
        button
            max-width 120px

</style>
