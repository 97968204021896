
export default [
    {
        path: '/app/companies',
        name: 'app.companies',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-companies" */ './../views/App/Companies/Dashboard.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        }
    },
    {
        path: '/app/companies/list',
        name: 'app.companies.list',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-companies-list" */ './../views/App/Companies/List.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        }
    },
    {
        path: '/app/companies/cnpj',
        name: 'app.companies.cnpj',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-companies-cnpj" */ './../views/App/Companies/Cnpj.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        }
    },
    {
        path: '/app/companies/refresh/:id',
        name: 'app.companies.refresh',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-companies-refresh" */ './../views/App/Companies/Refresh.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        }
    },
    {
        path: '/app/companies/show/:id',
        name: 'app.companies.show',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-companies-show" */ './../views/App/Companies/Show.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        },
        children: [
            {
                path: 'dashboard',
                name: 'app.companies.dashboard',
                component: () => import(/* webpackChunkName: "app-companies-pages-dashboard" */ './../views/App/Companies/Pages/Dashboard.vue')
            },
            {
                path: 'address',
                name: 'app.companies.address',
                component: () => import(/* webpackChunkName: "app-companies-pages-address" */ './../views/App/Companies/Pages/Address.vue')
            },
            {
                path: 'groups',
                name: 'app.companies.groups',
                component: () => import(/* webpackChunkName: "app-companies-pages-groups" */ './../views/App/Companies/Pages/Groups.vue')
            },
            {
                path: 'campaign',
                name: 'app.companies.campaign',
                component: () => import(/* webpackChunkName: "app-companies-pages-campaign" */ './../views/App/Companies/Pages/Campaign.vue')
            },
            {
                path: 'response',
                name: 'app.companies.response',
                component: () => import(/* webpackChunkName: "app-companies-pages-responses" */ './../views/App/Companies/Pages/Responses.vue')
            },
            {
                path: 'extract',
                name: 'app.companies.extract',
                component: () => import(/* webpackChunkName: "app-companies-pages-extract" */ './../views/App/Companies/Pages/Extract.vue')
            },
            {
                path: 'account',
                name: 'app.companies.account',
                component: () => import(/* webpackChunkName: "app-companies-pages-account" */ './../views/App/Companies/Pages/Account.vue')
            },
            {
                path: 'balance',
                name: 'app.companies.balance',
                component: () => import(/* webpackChunkName: "app-companies-pages-balance" */ './../views/App/Companies/Pages/Balance.vue')
            },
            {
                path: 'users',
                name: 'app.companies.users',
                component: () => import(/* webpackChunkName: "app-companies-pages-users" */ './../views/App/Companies/Pages/Users.vue')
            },
            {
                path: 'clients',
                name: 'app.companies.clients',
                component: () => import(/* webpackChunkName: "app-companies-pages-clients" */ './../views/App/Companies/Pages/Clients.vue')
            }
        ]
    },
    {
        path: '/app/companies/key',
        name: 'app.companies.key',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-companies-key" */ './../views/App/Companies/Key.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        }
    },
    {
        path: '/app/companies/notifications',
        name: 'app.companies.notifications',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-companies-notifications" */ './../views/App/Companies/Notifications/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/companies/notifications/create',
        name: 'app.companies.notifications.create',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-companies-notifications-create" */ './../views/App/Companies/Notifications/Create.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/companies/notifications/edit/:id',
        name: 'app.companies.notifications.edit',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-companies-notifications-edit" */ './../views/App/Companies/Notifications/Edit.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/companies/channel',
        name: 'app.companies.channel',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-companies-channel" */ './../views/App/Companies/Channel.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/companies/consumption',
        name: 'app.companies.consumption',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-companies-consumption" */ './../views/App/Companies/Consumption.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/companies/cost-center',
        name: 'app.companies.cost_center',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-companies-consumption" */ './../views/App/Companies/CostCenter.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    }
]
