const data = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: 'tachometer-alt',
        route: 'app.dashboard',
        permition: ['S', 'A', 'C'],
        childrens: []
    },
    {
        key: 'companies',
        title: 'Empresas',
        icon: 'building',
        route: 'app.companies',
        permition: ['S', 'A', 'C'],
        childrens: [
            {
                key: 101,
                icon: '/svg/menu/company.svg',
                route: 'app.companies.list',
                title: 'Empresas',
                description: 'Analise as empresas cadastradas na plataforma.',
                permition: ['S', 'A', 'C']
            },
            {
                key: 102,
                icon: '/svg/menu/keywords.svg',
                route: 'app.companies.key',
                title: 'Chaves',
                description: 'Verifique quem é o cliente de determinada Key/Chave.',
                permition: ['S', 'A', 'C']
            },
            {
                key: 103,
                icon: '/svg/menu/notification.svg',
                route: 'app.companies.notifications',
                title: 'Notificações',
                description: 'Empresas que utilizam notificações.',
                permition: ['S', 'A']
            },
            // {
            //     key: 104,
            //     icon: '/svg/menu/webhook.svg',
            //     route: 'app.companies.webhook',
            //     title: 'Webhook',
            //     description: 'Empresas que utilizam webhook / callback.',
            //     permition: ['S', 'A']
            // },
            {
                key: 105,
                icon: '/svg/menu/cnpj.svg',
                route: 'app.companies.cnpj',
                title: 'Consulta CNPJ',
                description: 'Consulte o CNPJ de empresas.',
                permition: ['S', 'A']
            },
            {
                key: 106,
                icon: '/svg/menu/channels.svg',
                route: 'app.companies.channel',
                title: 'Empresas por Canal',
                description: 'Empresas que utilizam determinado canal.',
                permition: ['S', 'A']
            },
            {
                key: 107,
                icon: '/svg/menu/consumption.svg',
                route: 'app.companies.consumption',
                title: 'Consumo por empresa',
                description: 'Detalhes em créditos de cada empresa por consumo.',
                permition: ['S', 'A']
            },
            {
                key: 108,
                icon: '/svg/menu/financial.svg',
                route: 'app.companies.cost_center',
                title: 'Centro de Custos',
                description: 'Gerencia os centro de custos dos clientes.',
                permition: ['S', 'A']
            }
        ]
    },
    {
        key: 'campaigns',
        title: 'Campanhas',
        icon: 'paper-plane',
        route: 'app.campaigns',
        permition: ['S', 'A', 'C'],
        childrens: [
            {
                key: 201,
                icon: '/svg/menu/campaigns.svg',
                route: 'app.campaigns.list',
                title: 'Campanhas',
                description: 'Lista de campanhas.',
                permition: ['S', 'A', 'C']
            },
            {
                key: 202,
                icon: '/svg/menu/campaigns-pending.svg',
                route: 'app.campaigns.pending',
                title: 'Pendentes',
                description: 'Campanhas pendentes de envio.',
                permition: ['S', 'A']
            },
            {
                key: 203,
                icon: '/svg/menu/campaigns-status.svg',
                route: 'app.campaigns.status',
                title: 'Status',
                description: 'Reprocessar status.',
                permition: ['S', 'A']
            }
        ]
    },
    {
        key: 'messages',
        title: 'Mensagens',
        icon: 'comments',
        route: 'app.messages',
        permition: ['S', 'A'],
        childrens: [
            {
                key: 302,
                icon: '/svg/menu/sms.svg',
                route: 'app.messages.send',
                title: 'Envios',
                description: 'Faça pesquisa de mensagens enviadas, filtros por telefone ou id integração.',
                permition: ['S', 'A']
            },
            {
                key: 303,
                icon: '/svg/menu/responses.svg',
                route: 'app.messages.received',
                title: 'Recebimentos',
                description: 'Faça pesquisa de mensagens recebidas, filtros por telefone ou id integração.',
                permition: ['S', 'A']
            },
            {
                key: 302,
                icon: '/svg/menu/sms-all.svg',
                route: 'app.messages.all',
                title: 'Todos Envios',
                description: 'Faça pesquisa de mensagens enviadas, filtros por data e telefone.',
                permition: ['S', 'A']
            },
            {
                key: 303,
                icon: '/svg/menu/sms-block.svg',
                route: 'app.messages.block',
                title: 'Envios Bloqueados',
                description: 'Mensagens bloqueadas por limpeza preditiva, libere para envio por outro canal.',
                permition: ['S', 'A']
            }
        ]
    },
    {
        key: 'imports',
        title: 'Importações',
        icon: 'file-upload',
        route: 'app.imports',
        permition: ['S', 'A'],
        childrens: []
    },
    {
        key: 'users',
        title: 'Usuários',
        icon: 'users',
        route: 'app.users',
        permition: ['S', 'A'],
        childrens: []
    },
    {
        key: 'finances',
        title: 'Financeiro',
        icon: 'file-invoice-dollar',
        route: 'app.finances',
        permition: ['S', 'A'],
        childrens: [
            {
                key: 501,
                icon: '/svg/menu/invoiceblue.svg',
                route: 'app.finances.invoices',
                title: 'Faturas',
                description: 'Listagem de faturas emitidas, emissão de faturas.',
                permition: ['S', 'A']
            },
            {
                key: 502,
                icon: '/svg/menu/invoice-ibpt.svg',
                route: 'app.finances.ibpt',
                title: 'IBPT',
                description: 'Gerencie os IBPTs e suas validades.',
                permition: ['S', 'A']
            },
            {
                key: 503,
                icon: '/svg/menu/invoice-settings.svg',
                route: 'app.finances.billingsconfig',
                title: 'Configurações Cobrança',
                description: 'Gerencie as empresas que estarão disponíveis para gerar fatura.',
                permition: ['S', 'A']
            }
        ]
    },
    {
        key: 'reports',
        title: 'Relatórios',
        icon: 'file-alt',
        route: 'app.reports',
        permition: ['S', 'A', 'C'],
        childrens: [
            {
                key: 502,
                icon: '/svg/menu/reports-date.svg',
                route: 'app.reports.analytical',
                title: 'Analítico',
                description: 'Relatório analítico por data com detalhes de envios e recebimentos.',
                permition: ['S', 'A']
            },
            {
                key: 501,
                icon: '/svg/menu/reports-synthetic.svg',
                route: 'app.reports.synthetic',
                title: 'Sintético',
                description: 'Relatório sintético para visualização em tela.',
                permition: ['S', 'A']
            },
            {
                key: 502,
                icon: '/svg/menu/financial.svg',
                route: 'app.reports.credits',
                title: 'Créditos',
                description: 'Total de créditos por clientes pré pagos da Ziad / Comercial.',
                permition: ['S', 'A', 'C']
            },
            {
                key: 503,
                icon: '/svg/menu/reports-provider.svg',
                route: 'app.reports.provider',
                title: 'Fornecedores',
                description: 'Relatório fornecedores por data com total de envios e recebimentos.',
                permition: ['S', 'A']
            },
            {
                key: 504,
                icon: '/svg/menu/financialstatement.svg',
                route: 'app.reports.tax',
                title: 'Taxas',
                description: 'Taxas dos clientes e seus serviços.',
                permition: ['S', 'A']
            },
            {
                key: 504,
                icon: '/svg/menu/reports.svg',
                route: 'app.reports.sends_receiveds',
                title: 'Envios e Recebimentos',
                description: 'Processar dados dos relatórios para gráficos.',
                permition: ['S', 'A']
            },
            {
                key: 505,
                icon: '/svg/menu/sms-block.svg',
                route: 'app.reports.blockips',
                title: 'Block Ips',
                description: 'Gerenciamento de Ips Bloqueados.',
                permition: ['S', 'A']
            }
        ]
    }
]

const find = (key) => {
    let search = data.find((item) => {
        return item.key === key
    })
    return search.childrens
}

const findByKeys = (keys) => {
    let itens = []
    data.map((item) => {
        item.childrens.map((i) => {
            if (keys.includes(i.key)) {
                itens.push(i)
            }
        })
    })
    return itens
}

export default {
    data,
    find,
    findByKeys
}
