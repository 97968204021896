
export default [
    {
        path: '/error/500',
        name: 'app.error.internal',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-error-500-authenticated" */ './../views/Error/500.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-error-500-unauthenticated" */ './../views/Error/500.vue')
        }
    },
    {
        path: '/permission',
        name: 'app.error.permission',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-error-permission-authenticated" */ './../views/Error/Permission.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-error-permission-unauthenticated" */ './../views/Error/Permission.vue')
        }
    }
    // ,
    // {
    //     path: '*',
    //     name: 'app.error.notfound',
    //     components: {
    //         authenticated: () => import(/* webpackChunkName: "app-error-404-authenticated" */ './../views/Error/404.vue'),
    //         unauthenticated: () => import(/* webpackChunkName: "app-error-404-unauthenticated" */ './../views/Error/404.vue')
    //     }
    // }
]
