import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'
import querystring from 'querystringify'

import './../../security/interceptors'

const totalReceivedMonth = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/total-received-month?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar o total de recebimentos no mês')
        })
}

const totalSendMonth = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/total-send-month?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar o total de envios no mês')
        })
}

const totalTTSMonth = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/total-tts-month?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar o total de TTS no mês')
        })
}

const wordAudit = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/messages/word-audit/search?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar as mensagens auditadas.')
        })
}

export default {
    totalReceivedMonth,
    totalSendMonth,
    totalTTSMonth,
    wordAudit
}
