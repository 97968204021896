<template>
    <div class="app-options">
        <ul class="desktop">
            <li v-for="(item, key) in menu" :key="key">
                <router-link :to="{ name: item.route }">
                    <img :src="item.icon" v-if="item.icon">
                    <h3>{{ item.title }}</h3>
                    <span class="description">{{ item.description }}</span>
                </router-link>
            </li>
        </ul>
        <div class="mobile">
            <router-link v-for="(item, key) in menu" :key="key" :to="{ name: item.route }">
                <el-row align="middle">
                    <el-col :span="6" class="text-center">
                        <img :src="item.icon" v-if="item.icon">
                    </el-col>
                    <el-col :span="18">
                        <h3>{{ item.title }}</h3>
                    </el-col>
                </el-row>
            </router-link>
        </div>
    </div>
</template>

<script setup>

    import { ref, onMounted } from 'vue'
    import { useStore } from 'vuex'

    const $store = useStore()

    const props = defineProps({
        options: Array
    })

    const menu = ref([])

    onMounted(() => {

        menu.value = props.options.filter((item) => {
            return item.permition.includes($store.state.user.permition)
        })

    })



    // export default {
    //     name: 'component-options',
    //     props: ['options'],

    //     data () {
    //         return {
    //             menu: []
    //         }
    //     },

    //     mounted () {
    //         this.process()
    //     },

    //     methods: {

    //         process () {
    //             this.menu = this.options.filter((item) => {
    //                 return item.permition.includes(this.$store.state.user.permition)
    //             })
    //         }
    //     }
    // }

</script>

<style lang="stylus" scoped>

    @import './../assets/stylus/variables.styl'

    .app-options

        
        padding-top 30px

        .desktop
            display none

        .mobile

            a
                color $primary

            .el-col
                padding 10px 0

            h3
                font-size 16px

            img
                width 50px
                height 50px


    /* Tablet >= 768 pixels */
    @media (min-width: 768px)

        .app-options

            .desktop
                display block
                text-align center

                li
                    list-style none
                    display inline-block
                    padding 20px
                    vertical-align top

                    a
                        color #999
                        font-size 12px
                        padding 15px 0
                        display block
                        max-width 250px
                        margin auto
                        text-decoration none

                        img
                            transition-duration 0.3s
                            width 50px
                            height 50px

                        &:hover
                            img
                                transform scale(1.2)

                        h3
                            font-weight 700
                            font-size 14px
                            margin 5px 0
                            color #666


                        &:hover
                            h3
                                color $primary
                                text-decoration underline

            .mobile
                display none


</style>
