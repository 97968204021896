<template>
    <div class="app-consumption">

        <el-row class="ui-search box-shadow" align="bottom">
            <el-col :xs="24" :lg="20">
                <el-row :gutter="20">
                    <el-col :lg="6">
                        <label>Busca pelo nome</label>
                        <input type="text" v-model="consumption.search.term">
                    </el-col>
                    <el-col :lg="6">
                        <label>Assinatura</label>
                        <select v-model="consumption.search.signature_type">
                            <option value="">Todos</option>
                            <option value="P">Pré Pago</option>
                            <option value="C">Pós Pago</option>
                            <option value="T">Teste</option>
                        </select>
                    </el-col>
                    <el-col :lg="6">
                        <label>Tipo</label>
                        <select v-model="consumption.search.custom_type">
                            <option value="">Todos</option>
                            <option value="R">Revendas</option>
                            <option value="C">Clientes</option>
                        </select>
                    </el-col>
                    <el-col :lg="6">
                        <label>Status</label>
                        <select v-model="consumption.search.status">
                            <option value="">Todos</option>
                            <option value="A">Ativos</option>
                            <option value="I">Inativos</option>
                        </select>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :xs="24" :lg="4" class="text-right">
                <button @click="consumptionLoad()" class="ui-btn-default">Atualizar</button>
            </el-col>
        </el-row>
        
        <el-scrollbar :height="`${isMobile() ? '210px' : '410px' }`" :native="true" class="box-shadow mt-2">

            <el-table :data="consumptionList" stripe :default-sort="{ prop: 'send', order: 'descending' }" style="width: 100%" v-if="!consumption.loading">
                <template #empty>
                    <el-empty description="Nenhuma empresa para ser exibida" />
                </template>
                <el-table-column label="Cliente" prop="client" sortable>
                    <template #default="scope">
                        <strong class="nickname"><router-link :to="{ name: 'app.companies.refresh', params: { id: scope.row.id } }">{{ scope.row.nick_name }}</router-link></strong><br>
                        <span class="opacity-70">{{ scope.row.social_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Assinatura" align="center">
                    <template #default="scope">
                        {{ getSignatureType(scope.row.signature_type) }}
                    </template>
                </el-table-column>
                <el-table-column label="Tipo" align="center">
                    <template #default="scope">
                        {{ getCustomType(scope.row.custom_type) }}
                    </template>
                </el-table-column>
                <el-table-column label="Status" align="center">
                    <template #default="scope">
                        <el-tag v-if="scope.row.status != 'A'" type="info" effect="dark" round>Inativo</el-tag>
                        <el-tag v-if="scope.row.status == 'A'" type="success" effect="dark" round>Ativo</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="Serviço" align="center">
                    <template #default="scope">
                        {{ scope.row.service_name }}
                    </template>
                </el-table-column>
                <el-table-column label="Saldo *" align="center" prop="balance" sortable>
                    <template #default="scope">
                        <span class="balance" v-if="scope.row.signature_type !== 'C'">
                            {{ scope.row.balance }}
                        </span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column label="Envios / Recebimentos **" prop="send" align="center" sortable />
            </el-table>

            <ui-loading v-else text="Aguarde... Carregando lista de clientes..."></ui-loading>

            <div v-if="!consumption.loading && consumptionList.length == 0" class="clients-empty">
                <div v-if="consumption.search.term != ''">
                    <h3>Nenhum cliente encontrado para <u>{{ consumption.search.term }}</u>!</h3>
                </div>
            </div>

        </el-scrollbar>

        <div class="alert-custom box-shadow mt-2">
            * Atualização do saldo é em tempo real. (Pré Pago e Teste)<br>
            ** Total de envios e recebimentos do mês atual.<br>
            ** A soma não consta os envios e recebimentos do dia de hoje.<br>
            ** Revendas tem a soma unificada.
        </div>

    </div>
</template>

<script setup>

    import { computed, reactive, getCurrentInstance, onMounted } from 'vue'
    import ConsumptionServices from './../services/companies/consumption'

    const app = getCurrentInstance()
    const emitter = app.appContext.config.globalProperties.emitter

    const props = defineProps({
        type: String
    })

    const consumption = reactive({
        search: {
            term: '',
            signature_type: '',
            custom_type: '',
            status: ''
        },
        status: false,
        loading: false,
        sort: {
            name: '',
            order: 'desc'
        },
        list: []
    })

    const consumptionList = computed(() => {

        let result = consumption.list

        if (consumption.search.signature_type !== '') {
            result = result.filter((item) => {
                return item.signature_type === consumption.search.signature_type
            })
        }

        if (consumption.search.custom_type !== '') {
            result = result.filter((item) => {
                return item.custom_type === consumption.search.custom_type
            })
        }

        if (consumption.search.status !== '') {
            result = result.filter((item) => {
                return item.status === consumption.search.status
            })
        }

        if (consumption.search.term !== '') {
            result = result.filter((item) => {
                return item.social_name.toLowerCase().includes(consumption.search.term.toLowerCase())
            })
        }

        return result
    })

    const getConsumption = async () => {

        consumption.loading = true
        
        let response = await ConsumptionServices.findBy({
            by: props.type
        })

        if (response && response.status) {
            consumption.list = response.results || []
        }

        consumption.loading = false

    }

    onMounted(() => {
        getConsumption()
    })




    // import ConsumptionServices from './../services/companies/consumption'

    // export default {
    //     name: 'component-consumption-by',

    //     props: ['type'],

    //     data () {
    //         return {
    //             consumption: {
    //                 search: {
    //                     term: '',
    //                     signature_type: '',
    //                     custom_type: '',
    //                     status: ''
    //                 },
    //                 status: false,
    //                 loading: false,
    //                 sort: {
    //                     name: '',
    //                     order: 'desc'
    //                 },
    //                 list: []
    //             }
    //         }
    //     },

    //     computed: {
    //         consumptionList () {
    //             let result = this.consumption.list
    //             if (this.consumption.search.signature_type !== '') {
    //                 result = result.filter((item) => {
    //                     return item.signature_type === this.consumption.search.signature_type
    //                 })
    //             }
    //             if (this.consumption.search.custom_type !== '') {
    //                 result = result.filter((item) => {
    //                     return item.custom_type === this.consumption.search.custom_type
    //                 })
    //             }
    //             if (this.consumption.search.status !== '') {
    //                 result = result.filter((item) => {
    //                     return item.status === this.consumption.search.status
    //                 })
    //             }
    //             return result.filter((item) => {
    //                 return item.nick_name.toLowerCase().includes(this.consumption.search.term.toLowerCase()) || item.social_name.toLowerCase().includes(this.consumption.search.term.toLowerCase())
    //             })
    //         }
    //     },


    //     created () {
    //         this.consumptionLoad()
    //     },


    //     methods: {

    //         getCustomType (type) {
    //             switch (type) {
    //             case 'R':
    //                 return 'Revenda'
    //             case 'C':
    //                 return 'Cliente'
    //             default:
    //                 return '-'
    //             }
    //         },

    //         getSignatureType (type) {
    //             switch (type) {
    //             case 'C':
    //                 return 'Pós Pago'
    //             case 'T':
    //                 return 'Teste'
    //             case 'P':
    //                 return 'Pré Pago'
    //             default:
    //                 return '-'
    //             }
    //         },

    //         sortBy (name, order) {
    //             this.consumption.sort.name = name
    //             this.consumption.sort.order = order
    //             this.consumption.list = this.consumption.list.sort((a, b) => order === 'asc' ? a[name] - b[name] : b[name] - a[name])
    //         },

    //         /**
    //          * Carrega a listagem de consumação de seus clientes
    //          */
    //         async consumptionLoad () {
    //             try {
    //                 this.consumption.loading = true

    //                 let result = await ConsumptionServices.findBy({
    //                     by: this.type
    //                 })

    //                 if (result && result.status) {
    //                     this.consumption.list = result.results || []
    //                 }
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar o consumo.' })
    //             } finally {
    //                 this.consumption.loading = false
    //             }
    //         }
    //     }
    // }
</script>

<style lang="stylus" scoped>

    @import './../assets/stylus/variables.styl'

    .app-consumption

        .el-table
            font-size 12px

        .balance
            color $green
            font-size 18px
            font-weight bold


</style>
