import api from './../../security/api'
import querystring from 'querystringify'
import errorCatch from './../../helpers/errorCatch'

import './../../security/interceptors'

const all = () => {
    return api.get('/customers/all')
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a listagem de todos os clientes')
        })
}

const invoice = () => {
    return api.get('/customers/invoice')
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a listagem de todos os clientes')
        })
}

const findAll = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/customers?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a listagem de clientes')
        })
}

const find = (id) => {
    return api.get(`/customers/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar o cliente')
        })
}

const store = (data) => {
    return api.post('/customers', data)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível cadastrar o cliente')
        })
}

const update = (data) => {
    return api.put(`/customers/${data.id}`, data)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível atualizar o cliente')
        })
}

const destroy = (id) => {
    return api.delete(`/customers/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível excluir o cliente')
        })
}

const profile = () => {
    return api.get('/customers/profile')
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar o perfil do cliente')
        })
}

const usersList = (id) => {
    return api.get(`/customers/${id}/users`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os usuários do cliente')
        })
}

const usersPassword = (data) => {
    return api.post('/password/forgot', data)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível cadastrar o cliente')
        })
}

export default {
    all,
    invoice,
    findAll,
    find,
    store,
    update,
    destroy,
    profile,
    usersList,
    usersPassword
}
