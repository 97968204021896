
export default [
    {
        path: '/app/reports',
        name: 'app.reports',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-reports" */ './../views/App/Reports/Dashboard.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        }
    },
    {
        path: '/app/reports/synthetic',
        name: 'app.reports.synthetic',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-reports-synthetic" */ './../views/App/Reports/Synthetic/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/reports/analytical',
        name: 'app.reports.analytical',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-reports-analytical" */ './../views/App/Reports/Analytical/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/reports/analytical/create',
        name: 'app.reports.analytical.create',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-reports-analytical-create" */ './../views/App/Reports/Analytical/Create.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/reports/credits',
        name: 'app.reports.credits',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-reports-credits" */ './../views/App/Reports/Credits/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        }
    },
    {
        path: '/app/reports/provider',
        name: 'app.reports.provider',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-reports-provider" */ './../views/App/Reports/Providers/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/reports/tax',
        name: 'app.reports.tax',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-reports-tax" */ './../views/App/Reports/Tax/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/reports/sends-receiveds',
        name: 'app.reports.sends_receiveds',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-reports-sends-receiveds" */ './../views/App/Reports/SendsReceiveds/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/reports/blockips',
        name: 'app.reports.blockips',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-reports-blockips" */ './../views/App/Reports/BlockIps/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/reports/blockips/create',
        name: 'app.reports.blockips.create',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-reports-blockips-create" */ './../views/App/Reports/BlockIps/Create.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    }
]
