<template>
    <div class="form-notifications">

        <el-row justify="center">
            <el-col :xs="24" :lg="12" v-if="search.open && type === 'create'">

                <div class="ui-search box-shadow mb-2">
                    <label>Digite o nome da empresa:</label>
                    <input class="mt-1 mb-1" type="text" @keyup.enter="loadClientsBy('search')" v-model="search.term">
                    <button type="button" class="ui-btn-primary" @click="loadClientsBy('search')" :disabled="!search.term">Buscar</button>
                </div>

                <div v-if="isMobile()">
                    <el-card v-for="(item, key) in search.list" :key="key" class="mb-1 box-card">
                        <div>
                            <h3>{{ item.nick_name }}</h3>
                            <strong>Razão Social.:</strong> {{ item.social_name }}<br>
                            <strong>Tipo:</strong> {{ item.custom_type === 'R' ? 'Revenda' : 'Cliente' }}<br>
                            <button type="button" class="ui-btn-primary mt-1" @click="selectCompany(item)">
                                Selecionar
                            </button>
                        </div>
                    </el-card>
                    <el-card v-if="search.list.length == 0">
                        Nenhuma empresa encontrada...
                    </el-card>
                </div>

                <el-table v-else :data="search.list" :scrollbar-always-on="true" height="300px" empty-text="Nenhuma empresa encontrada..." class="box-shadow" style="width: 100%">
                    <el-table-column label="Empresa">
                        <template #default="scope">
                            <h4 class="m-0">{{ scope.row.nick_name }}</h4>
                            <p class="opacity-50 m-0">{{ scope.row.social_name }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tipo">
                        <template #default="scope">
                            {{ scope.row.custom_type === 'R' ? 'Revenda' : 'Cliente' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="right">
                        <template #default="scope">
                            <button type="button" class="ui-btn-primary" @click="selectCompany(scope.row)">
                                Selecionar
                            </button>
                        </template>
                    </el-table-column>
                </el-table>
                    
            </el-col>
        </el-row>

        <el-row justify="center">
            <el-col :xs="24" :lg="12">
                <div v-if="form.id">

                    <div class="ui-box-white box-shadow mb-2">
                        <el-row :gutter="20" align="middle">
                            <el-col :xl="24" :lg="12">
                                <h2>{{ form.nick_name }}</h2>
                                <p class="opacity-50 m-0">{{ form.social_name }}</p>
                                <p class="xs-mb-2 mt-0"><strong>Tipo:</strong> {{ form.custom_type === 'R' ? 'Revenda' : 'Cliente' }}</p>
                            </el-col>
                            <el-col :xl="24" :lg="12">
                                <label>E-mail(s) para notificação. *</label>
                                <input type="text" v-model="form.emails">
                            </el-col>
                        </el-row>
                    </div>

                    <div v-if="form.custom_type === 'R'">

                        <div class="alert-custom box-shadow mb-2">
                            <h4>Observações:</h4>
                            <p>&bull; Clientes inativos não são adicionados no e-mail de notificação.</p>
                            <p>&bull; Para adicionar mais de um e-mail nas notificações separe por vírgula.</p>
                            <p>&bull; Para não exibir notificação de determinado cliente basta informar 0 no campo limite.</p>
                        </div>

                        <div class="ui-box-white box-shadow">
                            <table class="el-table">
                                <thead>
                                    <tr>
                                        <th>Empresa</th>
                                        <th>Serviços</th>
                                        <th>Status Empresa</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, key) in provider.list" :key="key">
                                        <td>
                                            <h5>{{ item.nick_name }}</h5>
                                            <p>{{ item.social_name }}</p>
                                        </td>
                                        <td>
                                            <div v-for="(i, k) in item.channels" :key="k">
                                                <input :label="getServiceName(i.id_services)" v-model="i.limit_credit_notify">
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="item.deleted_at">
                                                <el-tag type="danger">Deletado</el-tag>
                                            </div>
                                            <div v-else>
                                                <el-tag type="info" v-if="item.status != 'A'">Inativo</el-tag>
                                                <el-tag type="success" v-if="item.status == 'A'">Ativo</el-tag>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div v-else>

                        <div class="alert-custom box-shadow mb-2">
                            <h4>Observações:</h4>
                            <p>&bull; Para adicionar mais de um e-mail nas notificações separe por vírgula.</p>
                            <p>&bull; Para não exibir notificação de determinado cliente basta informar 0 no campo limite.</p>
                        </div>

                        <div class="ui-box-white box-shadow">
                            <table class="el-table ">
                                <thead>
                                    <tr>
                                        <th class="text-left">Serviço</th>
                                        <th class="text-left">Limite</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, key) in form.channels" :key="key">
                                        <td>{{ getServiceName(item.id_services) }}</td>
                                        <td>
                                            <input type="number" v-model="item.limit_credit_notify">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <br>

                    <div v-if="(form.custom_type === 'C' && form.signature_type === 'P') || form.custom_type === 'R'" class="action-buttons">
                        <button @click="saveForm()" :disabled="!form.emails" class="mr-1 ui-btn-primary">
                            {{ type === 'create' ? 'Cadastrar' : 'Atualizar' }}
                        </button>
                        <router-link :to="{ name : 'app.companies.notifications' }">
                            <button @click="search.open = false" class="ui-btn-none">Cancelar</button>
                        </router-link>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import NotificationsServices from './../../services/companies/notifications'

    export default {
        name: 'component-forms-notifications',
        props: ['type'],

        
        data () {
            return {
                provider: {
                    list: []
                },
                search: {
                    open: true,
                    term: '',
                    loading: false,
                    columns: [
                        { title: 'Empresa', name: 'result' },
                        { title: 'Tipo', name: 'type' },
                        { title: null, name: 'select' }
                    ],
                    list: []
                },
                form: {
                    id: '',
                    nick_name: '',
                    social_name: '',
                    custom_type: '',
                    signature_type: '',
                    limit_credit_notify: 0,
                    emails: '',
                    channels: []
                }
            }
        },


        async created () {
            if (this.type === 'edit') {
                this.loadNotification(this.$route.params.id)
            }
        },


        methods: {
            selectCompany (data) {
                this.search.open = false
                this.form.id = data.id
                this.form.nick_name = data.nick_name
                this.form.social_name = data.social_name
                this.form.signature_type = data.signature_type
                this.form.custom_type = data.custom_type
                this.form.channels = data.channels
                if (data.custom_type === 'R') {
                    this.loadClientsBy('provider')
                }
            },

            // getServiceName (id) {
            //     switch (id) {
            //     case 1: return 'SMS'
            //     case 2: return 'Flash'
            //     case 3: return '3'
            //     case 4: return 'TTS'
            //     case 5: return 'SMS sem resposta'
            //     case 6: return 'Flash sem resposta'
            //     }
            //     return id
            // },

            loadClientsBy (type) {
                if (type === 'search') {
                    this.searchClients({ name: this.search.term }, 'search')
                } else {
                    this.searchClients({ id_provider: this.form.id }, 'provider')
                }
            },

            async loadNotification (id) {
                try {
                    this.emitter.emit('loading', { status: true, message: 'Aguarde enquanto fazemos a busca...' })

                    let result = await NotificationsServices.show(id)

                    if (result && result.status) {
                        this.selectCompany(result.results.company)
                        this.form.emails = result.results.notification.email
                        this.emitter.emit('loading', { status: false })
                        return
                    }

                    throw Error(result.message)
                } catch (e) {
                    this.emitter.emit('loading', { status: false })
                    this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar os canais.' })
                }
            },

            async searchClients (data, type) {
                try {
                    this.emitter.emit('loading', { status: true, message: 'Aguarde enquanto fazemos a busca...' })

                    let result = await NotificationsServices.search(data)

                    if (result && result.status) {
                        if (type === 'search') {
                            this.search.list = result.results
                        } else {
                            this.provider.list = result.results
                        }
                        this.emitter.emit('loading', { status: false })
                        return
                    }

                    throw Error(result.message)
                } catch (e) {
                    this.emitter.emit('loading', { status: false })
                    this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar os canais.' })
                }
            },

            async saveForm () {
                try {
                    this.emitter.emit('loading', { status: true, message: 'Aguarde enquanto salvamos os dados...' })

                    let content = []

                    if (this.form.custom_type === 'R') {
                        this.provider.list.forEach(item => {
                            item.channels.forEach(i => {
                                content.push({
                                    id_companies: item.id,
                                    id_services: i.id_services,
                                    limit: i.limit_credit_notify
                                })
                            })
                        })
                    } else {
                        this.form.channels.forEach(item => {
                            content.push({
                                id_companies: item.id_companies,
                                id_services: item.id_services,
                                limit: item.limit_credit_notify
                            })
                        })
                    }

                    let data = {
                        id_companies: this.form.id,
                        type: this.form.custom_type === 'R' ? 'credits_resales' : 'credits_clients',
                        email: this.form.emails,
                        content: content
                    }

                    let result

                    if (this.type === 'create') {
                        result = await NotificationsServices.store(data)
                    } else {
                        result = await NotificationsServices.update(data)
                    }

                    if (result && result.status === 400) {
                        for (const key in result.errors) {
                            if (result.errors.hasOwnProperty(key)) {
                                const element = result.errors[key]
                                this.emitter.emit('notify', { type: 'error', message: element[0] })
                                return
                            }
                        }
                        return
                    } else if (result && result.status) {
                        this.emitter.emit('loading', { status: false })
                        this.emitter.emit('notify', { type: 'success', message: result.message || 'Os dados foram salvos com sucesso.' })
                        this.$router.push({ name: 'app.companies.notifications' })
                        return
                    }

                    throw Error(result.message)
                } catch (e) {
                    this.emitter.emit('loading', { status: false })
                    this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível salvar os dados.' })
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>

    @import './../../assets/stylus/variables.styl'

    .form-notifications

        .xs-mb-2
            margin-bottom 20px

        .el-table
            font-size 12px

    @media screen and (min-width: 768px)

        .form-notifications

            .xs-mb-2
                margin-bottom 0



</style>
