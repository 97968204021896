<template>
    <div class="app-body">

        <div class="el-drawer-principal">
            <el-drawer v-model="drawer" direction="ltr" size="300px">
                <div class="app-body-navigation">
                    <div class="logo">
                        <a href="javascript:;" @click="goToRoute('app.dashboard')">
                            <img src="/svg/logo-white.svg" />
                        </a>
                    </div>
                    <ul class="menu">
                        <li v-for="(item, key) in menu.data" :key="key">
                            <a href="javascript:;" @click="goToRoute(item.route)" :key="key">
                                <el-icon :size="16">
                                    <ArrowRight />
                                </el-icon>
                                {{ item.title }}
                            </a>
                        </li>
                    </ul>
                    <div class="user">
                        <el-row align="middle">
                            <el-col :span="4">
                                <el-icon :size="20" class="el-icon-user"><UserFilled /></el-icon>
                            </el-col>
                            <el-col :span="10">
                                Olá, <br>
                                <strong>{{ firstName }}</strong>
                            </el-col>
                            <el-col :span="10" class="text-right">
                                <el-button color="#3f234a" :bg="true" @click="logout()" class="opacity-50">
                                    <el-icon :size="18" class="mr-1"><SwitchButton /></el-icon> Sair
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-drawer>
        </div>

        <div class="app-body-main-content">
            <header class="header">

                <el-row align="middle">
                    <el-col :xs="4" :lg="8">
                        <a href="javascript:;" @click="drawer = true">
                            <div class="icon nav-icon">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="16" :lg="8" class="text-center">
                        <a href="javascript:;" @click="goToRoute('app.dashboard')">
                            <img src="/svg/logo-white-horizontal.svg" alt="Plataforma de SMS" class="logo" />
                        </a>
                    </el-col>
                    <el-col :xs="4" :lg="8" class="text-right">
                        <div v-if="!isMobile()">
                            Olá, <strong>{{ firstName }}</strong>
                            <a href="javascript:;" class="logout" @click="logout()">Sair</a>
                        </div>
                    </el-col>
                </el-row>

            </header>
            <main>
                <router-view name="authenticated"></router-view>
            </main>
            <div class="version-footer">
                {{ version }}
            </div>
        </div>
        
    </div>
</template>

<script>
    import { version } from './../../package.json'
    import SetInterval from 'set-interval'
    import menu from './../config/menu'
    
    export default {
        name: 'authenticated',

        data () {
            return {
                menu: menu,
                drawer: false,
                version: `v.${version}`
            }
        },

        computed: {

            // Pega os 15 primeiros caracteres do primeiro nome do usuário
            firstName () {
                if (this.$store.state.user && this.$store.state.user.firstName) {
                    return this.$store.state.user.firstName.substring(0, 15)
                }
                return ''
            }
        },

        mounted  () {

            // Iniciamos o layout com um refresh token caso ele apertar F5
            // antes de dar os 8 minutos para próximo refreshtoken
            this.refreshToken()

            SetInterval.start(() => {
                this.refreshToken()
            }, 60000, 'refreshToken')

        },

        unmounted () {
            SetInterval.clear('refreshToken')
            console.log('Unmounted refreshToken')
        },

        methods: {

            goToRoute (route) {
                this.drawer = false
                this.$router.push({ name: route })
            },

            
            // Carrega a listagem de fornecedores com sua consumação
            async logout () {
                this.$store.dispatch('logout').then((response) => {
                    this.$router.push({ name: 'auth.login' })
                }).catch((response) => {
                    this.$router.push({ name: 'auth.login' })
                })
            },

            /**
             * Faz o refresh token
             */
             refreshToken () {
                this.$store.dispatch('refreshToken').then((response) => {
                    if (response.code !== 200) {
                        this.logout()
                    }
                }).catch((response) => {
                    if (response.code !== 200) {
                        this.logout()
                    }
                })
            }
        }

    }
</script>

<style lang="stylus">

    @import './../assets/stylus/variables.styl'

    .el-drawer-principal

        .el-overlay
            z-index 2002 !important

        .el-drawer
            background-color #3f234a !important
            
            .el-drawer__header
                margin 0

            button
                &:hover
                    background none
                    i
                        color #fff

    .app-body
        min-height 100vh
        background-color #f5f5f5

        .app-body-navigation
            width 100%

            .logo
                padding 0 15px
                margin-bottom 30px
                opacity 0.6
                img
                    width 180px

            .menu
                list-style none
                color #fff
                padding-left 0
                opacity 0.5
                li
                    a
                        display block
                        padding 7px 10px
                        color #fff
                        font-size 15px
                        text-decoration none
                        text-transform uppercase
                        opacity 0.6
                        &:hover
                            opacity 1
                        &.active
                            background #f5f5f5
                            color $primary

            .user
                padding 20px 10px
                color #999
                font-size 10px
                line-height 16px
                position fixed
                bottom 0
                width 230px
                strong
                    text-transform uppercase
                    font-size 12px
                .el-icon-user
                    border 1px #999 solid
                    padding 5px
                    border-radius 50px
                    opacity 0.5


        .app-body-main-content
            min-height 100vh

            header.header
                font-size 12px
                color #fff
                padding 15px 0
                margin-bottom 20px
                background $primary
                -webkit-box-shadow 0px 0px 5px 0px rgba(0,0,0,0.15)
                -moz-box-shadow 0px 0px 5px 0px rgba(0,0,0,0.15)
                box-shadow 0px 0px 5px 0px rgba(0,0,0,0.15)

                // background #332865
                // background linear-gradient(45deg,#332865,#8a2c8a 70%)

                position fixed
                width 100%
                z-index 2001

                img.logo
                    width 160px

                .nav-icon
                    width 22px
                    height 16px
                    position relative
                    cursor pointer
                    display inline-block
                    margin-left 15px

                .nav-icon span
                    background-color #fff
                    position absolute
                    border-radius: 2px
                    transition .3s cubic-bezier(.8, .5, .2, 1.4)
                    width 100%
                    height 2px

                .nav-icon span:nth-child(1)
                    top 0px
                    left 0px
                    width 90%

                .nav-icon span:nth-child(2)
                    top 7px
                    left 0px
                    width 110%

                .nav-icon span:nth-child(3)
                    bottom 0px
                    left 0px
                    width 70%

                .nav-icon:hover span
                    width 100%

                a.logout
                    color #fff
                    margin-left 10px
                    margin-right 15px
                    padding-left 10px
                    border-left 1px #999 dotted

            main
                padding 15px
                padding-top 80px
                min-height 78vh

        .version-footer
            padding 10px
            text-align center
            font-size 11px
            color #999
    
    
    @media screen and (min-width: 768px)

        .app-body

            .app-body-navigation

                .menu
                    li
                        a
                            font-size 12px


</style>
