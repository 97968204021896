import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'

import './../../security/interceptors'

const all = () => {
    return api.get('/appconfigs')
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar as configurações')
        })
}

export default {
    all
}
