
export default [
    {
        path: '/app/users',
        name: 'app.users',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-users" */ './../views/App/Users/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/users/show/:id',
        name: 'app.users.show',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-users-show" */ './../views/App/Users/Show.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        },
        children: [
            {
                path: 'logs',
                name: 'app.users.logs',
                component: () => import(/* webpackChunkName: "app-users-show-logs" */ './../views/App/Users/Pages/Logs.vue')
            },
            {
                path: 'campaigns',
                name: 'app.users.campaigns',
                component: () => import(/* webpackChunkName: "app-users-show-campaigns" */ './../views/App/Users/Pages/Campaigns.vue')
            }
        ]
    }
]
