import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'
import querystring from 'querystringify'

import './../../security/interceptors'

const all = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/finances/ibpt?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a lista de IBPTs')
        })
}

const show = (id) => {
    return api.get(`/finances/ibpt/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar o ibpt')
        })
}

const store = (data) => {
    return api.post('/finances/ibpt', data)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível cadastrar o ibpt')
        })
}

const update = (data) => {
    return api.put('/finances/ibpt', data)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível cadastrar o ibpt')
        })
}

export default {
    all,
    show,
    store,
    update
}
