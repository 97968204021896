import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'
import querystring from 'querystringify'

import './../../security/interceptors'

const movile = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/status/movile?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os status')
        })
}

const movileInterval = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/status/movile/interval?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os status')
        })
}

const infobip = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/status/infobip?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os status')
        })
}

const infobipInterval = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/status/infobip/interval?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os status')
        })
}

const zenvia = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/status/zenvia?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os status')
        })
}

const zenviaInterval = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/status/zenvia/interval?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os status')
        })
}

export default {
    movile,
    movileInterval,
    infobip,
    infobipInterval,
    zenvia,
    zenviaInterval
}
