import { createApp } from 'vue'
import router from './router'
import store from './store'
import mixins from './mixins'
import App from './App.vue'

import Money from './components/Money.vue'
import FormIbpt from './components/Forms/Ibpt.vue'
import FormBillingsConfig from './components/Forms/BillingsConfig.vue'
import FormNotifications from './components/Forms/Notifications.vue'
import FormInvoice from './components/Forms/Invoice.vue'
import Loading from './components/Loading.vue'
import CampaignCard from './components/CampaignCard.vue'
import CampaignCardStatistics from './components/CampaignCardStatistics.vue'
import CompanieMonth from './components/CompanieMonth.vue'
import ConsumptionBy from './components/ConsumptionBy.vue'
import Options from './components/Options.vue'
import SendMonth from './components/SendMonth.vue'
import SummaryStatus from './components/SummaryStatus.vue'
import LastMinutes from './components/LastMinutes.vue'
import Top10 from './components/Top10.vue'
import TotalPerHour from './components/TotalPerHour.vue'
import TotalPerMinute from './components/TotalPerMinute.vue'

import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'

import mitt from 'mitt'
const emitter = mitt()

const app = createApp(App)

app.config.globalProperties.emitter = emitter

app.component('ui-money', Money)
app.component('ui-loading', Loading)
app.component('ui-form-ibpt', FormIbpt)
app.component('ui-form-billings-config', FormBillingsConfig)
app.component('ui-form-invoice', FormInvoice)
app.component('ui-form-notifications', FormNotifications)
app.component('ui-campaign-card', CampaignCard)
app.component('ui-campaign-card-statistics', CampaignCardStatistics)
app.component('ui-companie-month', CompanieMonth)
app.component('ui-consumption-by', ConsumptionBy)
app.component('ui-options', Options)
app.component('ui-summary-status', SummaryStatus)
app.component('ui-send-month', SendMonth)
app.component('ui-last-minutes', LastMinutes)
app.component('ui-top-10', Top10)
app.component('ui-total-per-hour', TotalPerHour)
app.component('ui-total-per-minute', TotalPerMinute)

app.use(ElementPlus)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


app.use(store)

app.use(router)

app.mixin(mixins)

app.mount('#app')

