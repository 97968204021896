
export default [
    {
        path: '/',
        name: 'auth.login',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-redirect" */ './../views/Auth/Redirect.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: false
        }
    },
    {
        path: '/logout',
        name: 'auth.logout',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-logout" */'./../views/Auth/Logout.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-logout" */ './../views/Auth/Logout.vue')
        },
        meta: {
            auth: false
        }
    }
]
