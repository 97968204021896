import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'
import querystring from 'querystringify'

import './../../security/interceptors'

const all = () => {
    return api.get(`/status`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os status.')
        })
}

const summary = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/status/summary?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os status.')
        })
}

const byStatus = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/status/by-status?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os status.')
        })
}

const messages = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/status/messages?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar os status.')
        })
}

export default {
    all,
    summary,
    byStatus,
    messages
}
