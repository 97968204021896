<template>
    <div class="box-shadow" v-loading="data.loading">
        <div class="box-title">Últimas acima de <u>{{ data.total }}</u></div>
        <el-scrollbar height="300px" :native="true">
            <el-table :data="data.list" empty-text="Sem dados para exibir" style="width: 100%" >
                <el-table-column>
                    <template #default="scope">
                        <div><strong>Enviado em:</strong> {{ getDateFormatUTC(scope.row.sented_at, true) }}</div>
                        <router-link :to="{ name : 'app.campaigns.show', params: { id: scope.row.id }  }">
                            <strong>Título:</strong> {{ scope.row.title }}
                        </router-link>
                        <div><strong>Empresa:</strong> {{ scope.row.company.nick_name }}</div>
                        <div><strong>Contatos:</strong> {{ scope.row.totalContacts }}</div>
                        <div><strong>Origem:</strong> {{ scope.row.origin }}</div>
                    </template>
                </el-table-column>
            </el-table>
        </el-scrollbar>
        <div class="box-footer box-filter">
            <select v-model="data.total">
                <option :value="400">400 contatos</option>
                <option :value="300">300 contatos</option>
                <option :value="200">200 contatos</option>
                <option :value="100">100 contatos</option>
            </select>
            <button type="button" class="ui-btn-default" @click="load()">Ok</button>
        </div>
    </div>
</template>

<script setup>

    import { ref, getCurrentInstance } from 'vue'
    import CampaignsByMinute from './../services/campaigns'

    const app = getCurrentInstance()
    const emitter = app.appContext.config.globalProperties.emitter

    const data = ref({
        loading: false,
        total: 400,
        list: []
    })

    const load = async () => {
        try {
            data.value.loading = true
            let result = await CampaignsByMinute.findLastMinutes({ total: data.value.total })
            if (result && result.status) {
                data.value.list = result.results
            }
        } catch (e) {
            emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de totais.' })
        } finally {
            data.value.loading = false
        }
    }




    // export default {
    //     name: 'component-last-minutes',

    //     data () {
    //         return {
    //             data: {
    //                 loading: false,
    //                 total: 400,
    //                 list: []
    //             }
    //         }
    //     },

    //     methods: {
    //         async load () {
    //             try {
    //                 this.data.loading = true
    //                 let result = await CampaignsByMinute.findLastMinutes({ total: this.data.total })
    //                 if (result && result.status) {
    //                     this.data.list = result.results
    //                 }
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de totais.' })
    //             } finally {
    //                 this.data.loading = false
    //             }
    //         }
    //     }
    // }
</script>
