<template>
    <div class="box-shadow" v-loading="loading">
        <div class="box-title">
            Top 10 de <u>{{ dateFormat(date, false) }}</u>
        </div>
        <el-scrollbar height="300px" :native="true">
            <el-table :data="list" empty-text="Sem dados para exibir" style="width: 100%">
                <el-table-column prop="total" label="Total" width="80" />
                <el-table-column label="Empresa">
                    <template #default="scope">
                        <router-link :to="{ name : 'app.companies.refresh', params: { id: scope.row.id }  }">
                            {{ scope.row.company }}
                        </router-link>
                    </template>
                </el-table-column>
            </el-table>
        </el-scrollbar>
        <div class="box-footer box-filter">
            <input type="date" v-model="date" class="input-orange" /> <button type="button" class="ui-btn-default" @click="load()">Ok</button>
        </div>
    </div>
</template>

<script setup>

    import { ref, onMounted, getCurrentInstance } from 'vue'
    import MessagesServices from './../services/messages'

    const app = getCurrentInstance()
    const emitter = app.appContext.config.globalProperties.emitter

    const date = ref(new Date().toISOString().slice(0, 10))
    const loading = ref(false)
    const list = ref([])

    const load = async () => {
        try {
            loading.value = true

            let query = {
                date: date.value
            }

            let result = await MessagesServices.top10(query)
            
            if (result && result.status) {
                list.value = result.results
            }
        } catch (e) {
            console.log(e)
            emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de totais.' })

        } finally {
            loading.value = false
        }
    }

    onMounted(() => {
        load()
    })


    // import MessagesServices from './../services/messages'

    // export default {
    //     name: 'component-top-10',

    //     data () {
    //         return {
    //             filter: {
    //                 date: new Date().toISOString().slice(0, 10)
    //             },
    //             loading: false,
    //             list: []
    //         }
    //     },


    //     async created () {
    //         this.load()
    //     },

    //     methods: {
    //         async load () {
    //             try {
    //                 this.loading = true
    //                 let query = {
    //                     date: this.filter.date
    //                 }
    //                 let result = await MessagesServices.top10(query)
    //                 if (result && result.status) {
    //                     this.list = result.results
    //                 }
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de totais.' })

    //             } finally {
    //                 this.loading = false
    //             }
    //         }
    //     }
    // }
</script>
