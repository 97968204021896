
export default [
    {
        path: '/app/campaigns',
        name: 'app.campaigns',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-campaigns" */ './../views/App/Campaigns/Dashboard.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        }
    },
    {
        path: '/app/campaigns/list',
        name: 'app.campaigns.list',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-campaigns-list" */ './../views/App/Campaigns/Index.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        }
    },
    {
        path: '/app/campaigns/pending',
        name: 'app.campaigns.pending',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-campaigns-pending" */ './../views/App/Campaigns/Pending.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/campaigns/status',
        name: 'app.campaigns.status',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-campaigns-status" */ './../views/App/Campaigns/Status.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/campaigns/created',
        name: 'app.campaigns.created',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-campaigns-created" */ './../views/App/Campaigns/Created.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A']
        }
    },
    {
        path: '/app/campaigns/:id',
        name: 'app.campaigns.show',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-campaigns-show" */ './../views/App/Campaigns/Show.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        }
    }
]
