import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'
import querystring from 'querystringify'

import './../../security/interceptors'

const findAll = (objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/invoices?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a listagem de faturas')
        })
}

const find = (id) => {
    return api.get(`/invoices/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a fatura')
        })
}

const store = (data) => {
    return api.post('/invoices', data)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível cadastrar a fatura')
        })
}

const update = (id, data) => {
    return api.put(`/invoices/${id}`, data)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível cadastrar a fatura')
        })
}

const status = (id, objectQueryString) => {
    let query = querystring.stringify(objectQueryString)
    return api.get(`/invoices/${id}/status?${query}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível editar o status a fatura')
        })
}

const download = (id) => {
    return api.get(`/invoices/${id}/download`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        }
    })
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível fazer o download da fatura')
        })
}

export default {
    findAll,
    find,
    store,
    update,
    status,
    download
}
