<template>
    <div class="form-ibpt box-shadow">

        <div class="mb-2">
            <label>IBPT % (Ex.: 13.45)</label>
            <input type="number" v-model="form.ibpt_percentual">
        </div>

        <div class="mb-2">
            <label>IBPT Descrição</label>
            <input type="text" v-model="form.ibpt_notes">
        </div>

        <button class="ui-btn-primary mr-1" @click="saveForm()" :disabled="!form.ibpt_percentual || !form.ibpt_notes">
            {{ type === 'create' ? 'Cadastrar' : 'Atualizar' }}
        </button>

        <router-link :to="{ name : 'app.finances.ibpt' }">
            <button type="button" class="ui-btn-none">Cancelar</button>
        </router-link>

    </div>
</template>

<script>
    import FinancesServices from './../../services/finances/ibpt'

    export default {
        name: 'component-forms-ibpt',
        props: ['type'],

        data () {
            return {
                form: {}
            }
        },

        async created () {
            if (this.type === 'edit') {
                this.load(this.$route.params.id)
            }
        },

        methods: {
            async load (id) {
                try {
                    this.emitter.emit('loading', { status: true, message: 'Aguarde... Estamos carregando os dados do IBPT...' })

                    let result = await FinancesServices.show(id)

                    if (result && result.status) {
                        this.form = result.results
                        this.emitter.emit('loading', { status: false })
                        return
                    }

                    throw Error(result.message)
                } catch (e) {
                    this.emitter.emit('loading', { status: false })
                    this.emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar o IBPT.' })
                }
            },

            async saveForm () {
                try {
                    this.emitter.emit('loading', { status: true, message: 'Aguarde enquanto salvamos os dados...' })

                    let result

                    if (this.type === 'create') {
                        result = await FinancesServices.store(this.form)
                    } else {
                        result = await FinancesServices.update(this.form)
                    }

                    if (result && result.status === 400) {
                        for (const key in result.errors) {
                            if (result.errors.hasOwnProperty(key)) {
                                const element = result.errors[key]
                                this.emitter.emit('notify', { type: 'error', message: element[0] })
                                return
                            }
                        }
                        return
                    } else if (result && result.status) {
                        this.emitter.emit('loading', { status: false })
                        this.emitter.emit('notify', { type: 'success', message: result.message || 'Os dados foram salvos com sucesso.' })
                        this.$router.push({ name: 'app.finances.ibpt' })
                        return
                    }

                    throw Error(result.message)
                } catch (e) {
                    this.emitter.emit('loading', { status: false })
                    this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível salvar os dados.' })
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>

    @import './../../assets/stylus/variables.styl'

    .form-ibpt
        background #fff
        padding 20px

        input
            width 100%

</style>
