<template>
    <div class="summary-status" v-loading="loading">
       
        <!-- Total -->
        <div class="box-shadow">
            <div class="box-title">
                <span v-show="area == 'summary'">
                    Lista dos status do dia
                </span>
                <span v-show="area == 'bystatus'">
                    <el-icon :size="20" @click="area = 'summary'"><Back /></el-icon>
                    Empresas com mensagens com status <u>{{ findStatusName(params.status) }}</u> ({{ params.status }})
                </span>
                <span v-show="area == 'messages'">
                    <el-icon :size="20" @click="area = 'bystatus'"><Back /></el-icon>
                    {{ params.status }} - <u>{{ params.companie }}</u>
                </span>
            </div>

            <el-row align="middle">
                <el-col :xs="24" :lg="10">
                    <GChart
                        v-if="(chartData.length > 0 && !loading)"
                        type="PieChart"
                        :data="chartData"
                        :options="chartOptions"
                    />
                </el-col>
                <el-col :xs="24" :lg="14">
                    <el-scrollbar height="300px" :native="true">
                        <div v-show="area == 'summary'">
                            <el-table :data="data.summary" empty-text="Sem dados para exibir" style="width: 100%">
                                <el-table-column label="Id" width="80">
                                    <template #default="scope">
                                        <span class="dot" :style="{ 'background-color': scope.row.color }">{{ scope.row.status }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Descrição">
                                    <template #default="scope">
                                        <a href="javascript:;" @click="loadByStatus(scope.row.status)">{{ scope.row.name }}</a>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Qtde" align="right" width="60">
                                    <template #default="scope">
                                        {{ formatTotal(scope.row.total) }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div v-show="area == 'bystatus'">
                            <el-table :data="data.bystatus" empty-text="Sem dados para exibir" style="width: 100%">
                                <el-table-column label="Empresa">
                                    <template #default="scope">
                                        <a href="javascript:;" @click="loadMessages(scope.row.id_companies, scope.row.name)">{{ scope.row.name }}</a>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Qtde" align="center" width="200">
                                    <template #default="scope">
                                        {{ formatTotal(scope.row.count) }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div v-show="area == 'messages'">
                            <el-table :data="data.messages" empty-text="Sem dados para exibir" style="width: 100%">
                                <el-table-column label="Data" width="120">
                                    <template #default="scope">
                                        {{ dateFormat(scope.row.created_at, true) }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="phone" label="Telefone" width="100"></el-table-column>
                                <el-table-column prop="message" label="Mensagem"></el-table-column>
                            </el-table>
                            <el-pagination class="mt-2 mb-2" v-if="paging.total > paging.size" background layout="prev, pager, next" :total="paging.total" :page-size="paging.size" v-model:currentPage="paging.page" @current-change="loadMessages(params.id, params.companie)" />
                        </div>

                        <div class="alert-block-messages" v-if="existBlockMessages()">
                            Existe mensagens bloqueadas por limpeza preditiva. <router-link :to="{ name : 'app.messages.block' }">Verificar</router-link>
                        </div>
                        
                    </el-scrollbar>
                </el-col>
            </el-row>

            <div class="box-footer box-filter">
                <select v-model="params.day">
                    <option v-for="(item, key) in 31" :key="key" :value="item">{{ item }}</option>
                </select>
                <select v-model="params.month">
                    <option v-for="(item, key) in months" :key="key" :value="item">{{ item }}</option>
                </select>
                <select v-model="params.year">
                    <option v-for="(item, key) in years" :key="key" :value="item">{{ item }}</option>
                </select>
                <button type="button" class="ui-btn-default" @click="loadSummary()">Ok</button>
            </div>

        </div>

    </div>
</template>

<script setup>

    import { ref, reactive, computed, getCurrentInstance, onMounted, onUnmounted } from 'vue'
    import StatusServices from './../services/status'
    import SetInterval from 'set-interval'
    import { GChart } from 'vue-google-charts'
    import { isMobile } from './../mixins'

    const app = getCurrentInstance()
    const emitter = app.appContext.config.globalProperties.emitter

    const loading = ref(false)
    const chartData = ref([])
    const chartOptions = reactive({
        pieHole: 0.4,
        height: 300,
        legend: 'none',
        slices: [],
        chartArea: {
            width: '80%',
            height: '80%'
        }
    })
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const years = []
    const area = ref('summary')
    const data = reactive({
        summary: [],
        bystatus: [],
        status: [],
        messages: []
    })
    const params = reactive({
        id: 0,
        companie: '',
        day: 0,
        month: 0,
        year: 0
    })
    const paging = reactive({
        page: 1,
        size: 10,
        total: 0
    })

    onMounted(async () => {
        
        loading.value = true
        let date = new Date()
        params.day = date.getDate()
        params.month = date.getMonth() + 1
        params.year = date.getFullYear()

        for (let index = 2019; index <= params.year; index++) {
            years.push(index)
        }

        if (isMobile()){
            chartOptions.height = 200
        }

        await loadStatus()
        await loadSummary()

        loading.value = false

        SetInterval.start(() => {
            loadSummary()
        }, 120000, 'summary')
        
    })

    onUnmounted(() => {
        SetInterval.clear('summary')
    })

    const loadStatus = async () => {
        try {
            let response = await StatusServices.all()
            if (response && response.status) {
                data.status = response.results
            }
        } catch (e) {
            emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de status.' })
        }
    }

    const loadSummary = async () => {
        try {
            loading.value = true
            area.value = 'summary'
            let response = await StatusServices.summary({
                day: params.day,
                month: params.month,
                year: params.year
            })
            if (response && response.status) {
                data.summary = response.results
                processChart()
            }
        } catch (e) {
            this.emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de status.' })
        } finally {
            loading.value = false
        }
    }

    const loadByStatus = async (status) => {
        try {
            loading.value = true
            params.status = status
            data.bystatus = []
            area.value = 'bystatus'
            let result = await StatusServices.byStatus({
                day: params.day,
                month: params.month,
                year: params.year,
                status: params.status
            })
            if (result && result.status) {
                data.bystatus = result.results
            }
        } catch (e) {
            emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de status.' })
        } finally {
            loading.value = false
        }
    }

    const loadMessages = async (id, name) => {
        try {
            loading.value = true
            params.id = id
            params.companie = name
            area.value = 'messages'
            data.messages = []
            let response = await StatusServices.messages({
                day: params.day,
                month: params.month,
                year: params.year,
                status: params.status,
                id: params.id,
                page: paging.page
            })
            if (response && response.status) {
                data.messages = response.results
                paging.page = response.currentPage
                paging.total = response.rowCount
            }
        } catch (e) {
            emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de status.' })
        } finally {
            loading.value = false
        }
    }


    const findStatus = (status) => {
        return data.status.find((item) => {
            return item.id_status === status
        })
    }

    const findStatusName = (status) => {
        let find = data.status.find((item) => {
            return item.id_status === status
        })
        return find ? find.name : ''
    }

    const existBlockMessages = () => {
        let find = data.summary.find((item) => {
            return item.status === '250'
        })
        return find
    }

    const processChart = () => {
        chartData.value = []
        chartOptions.slices = []

        chartData.value.push(['Item 1', 'Item 2'])

        data.summary.forEach((item) => {
            chartData.value.push([item.name, parseInt(item.total)])
            chartOptions.slices.push({ color: item.color.trim() })
        })
    }

    const formatTotal = (total) => {
        return total.toLocaleString('pt-BR')
    }



    // import StatusServices from './../services/status'
    // import SetInterval from 'set-interval'
    // import { GChart } from 'vue-google-charts'

    // export default {
    //     name: 'component-summary-status',

    //     components: {
    //         GChart,
    //     },

    //     data () {
    //         return {
    //             loading: false,
    //             chartData: [],
    //             chartOptions: {
    //                 pieHole: 0.4,
    //                 height: 300,
    //                 legend: 'none',
    //                 slices: [],
    //                 chartArea: {
    //                     width: '80%',
    //                     height: '80%'
    //                 }
    //             },
    //             months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    //             years: [],
    //             area: 'summary',
    //             data: {
    //                 summary: [],
    //                 status: [],
    //                 bystatus: [],
    //                 messages: []
    //             },
    //             params: {
    //                 day: 0,
    //                 month: 0,
    //                 year: 0,
    //                 status: 0,
    //                 id: '',
    //                 companie: ''
    //             },
    //             paging: {
    //                 total: 0,
    //                 size: 10,
    //                 page: 1
    //             }
    //         }
    //     },

    //     async created () {
    //         this.loading = true
    //         let date = new Date()
    //         this.params.day = date.getDate()
    //         this.params.month = date.getMonth() + 1
    //         this.params.year = date.getFullYear()

    //         for (let index = 2019; index <= this.params.year; index++) {
    //             this.years.push(index)
    //         }

    //         if (this.isMobile()){
    //             this.chartOptions.height = 200
    //         }

    //         await this.loadStatus()
    //         await this.loadSummary()




    //         this.loading = false

    //         SetInterval.start(() => {
    //             this.loadSummary()
    //         }, 120000, 'summary')

            
    //     },

    //     unmounted () {
    //         SetInterval.clear('summary')
    //         console.log('Unmounted summary')
    //     },


    //     methods: {
    //         classObject (item) {
    //             return {
    //                 'color': item.color
    //             }
    //         },
    //         async loadStatus () {
    //             try {
    //                 let result = await StatusServices.all()
    //                 if (result && result.status) {
    //                     this.data.status = result.results
    //                 }
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de status.' })
    //             }
    //         },
    //         async loadSummary () {
    //             try {
    //                 this.loading = true
    //                 this.area = 'summary'
    //                 let result = await StatusServices.summary({
    //                     day: this.params.day,
    //                     month: this.params.month,
    //                     year: this.params.year
    //                 })
    //                 if (result && result.status) {
    //                     this.data.summary = result.results
    //                     this.processChart()
    //                 }
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de status.' })
    //             } finally {
    //                 this.loading = false
    //             }
    //         },
    //         async loadByStatus (status) {
    //             try {
    //                 this.loading = true
    //                 this.params.status = status
    //                 this.data.bystatus = []
    //                 this.area = 'bystatus'
    //                 let result = await StatusServices.byStatus({
    //                     day: this.params.day,
    //                     month: this.params.month,
    //                     year: this.params.year,
    //                     status: this.params.status
    //                 })
    //                 if (result && result.status) {
    //                     this.data.bystatus = result.results
    //                 }
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de status.' })
    //             } finally {
    //                 this.loading = false
    //             }
    //         },
    //         async loadMessages (id, name) {
    //             try {
    //                 this.loading = true
    //                 this.params.id = id
    //                 this.params.companie = name
    //                 this.area = 'messages'
    //                 this.data.messages = []
    //                 let result = await StatusServices.messages({
    //                     day: this.params.day,
    //                     month: this.params.month,
    //                     year: this.params.year,
    //                     status: this.params.status,
    //                     id: this.params.id,
    //                     page: this.paging.page
    //                 })
    //                 if (result && result.status) {
    //                     this.data.messages = result.results
    //                     this.paging.page = result.currentPage
    //                     this.paging.total = result.rowCount
    //                 }
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: 'Não foi possível carregar a lista de status.' })
    //             } finally {
    //                 this.loading = false
    //             }
    //         },
    //         findStatus (status) {
    //             return this.data.status.find((item) => {
    //                 return item.id_status === status
    //             })
    //         },
    //         findStatusName (status) {
    //             let find = this.data.status.find((item) => {
    //                 return item.id_status === status
    //             })
    //             return find ? find.name : ''
    //         },
    //         existBlockMessages () {
    //             let find = this.data.summary.find((item) => {
    //                 return item.status === '250'
    //             })
    //             return find
    //         },
    //         processChart () {
    //             this.chartData = []
    //             this.chartOptions.slices = []

    //             this.chartData.push(['Item 1', 'Item 2'])

    //             this.data.summary.forEach((item) => {
    //                 this.chartData.push([item.name, parseInt(item.total)])
    //                 this.chartOptions.slices.push({ color: item.color.trim() })
    //             })
    //         },
    //         formatTotal (total) {
    //             return total.toLocaleString('pt-BR')
    //         }
    //     }
    // }
</script>

<style lang="stylus" scoped>

    @import './../assets/stylus/variables.styl'

    .summary-status

        .el-table__cell
            padding 5px 0

        .box-title
            
            .el-icon
                position absolute
                margin -4px 0 0 -30px
                cursor pointer

        .box-filter
            select, button
                width 70px
        
        .dot
            display inline-block
            background #90a4ae
            border-radius: 12px;
            padding: 0 9px;
            color: #fff;

        .alert-block-messages
            background-color lighten($red, 80%)
            color $red
            text-align center
            padding 6px
            font-size 11px
            margin-top 10px
            a
                color $red !important
                text-decoration underline


</style>

