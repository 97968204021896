import { createStore } from 'vuex'
import storage from './security/storage'

import Login from './services/auth/login'
import RefreshToken from './services/auth/refresh-token'

const store = createStore({
    state () {
      return {
            authenticated: storage.get('authenticated', false),
            token: storage.get('token', null),
            user: storage.getObject('user', null),
            company: storage.getObject('company', null),
            nav: storage.get('nav', null),
            onLine: navigator.onLine
        }
    },
    mutations: {

        // Setando a autenticação do usuário
        setAuthenticated (state, authenticated) {
            storage.set('authenticated', authenticated)
            state.authenticated = authenticated
        },
    
        // Setamos o TOKEN recebido na hora da autenticação feita
        setToken (state, token) {
            storage.set('token', token)
            state.token = token
        },
    
        // Pega os dados do usuário e da empresa utilizando o TOKEN para carregar os dados necessário dad API
        setUser (state, user) {
            state.user = user
            storage.setObject('user', state.user)
        },
    
        // Pegar os dados da empresa utilizando o TOKEN para carregar os dados através da API
        setCompany (state, company) {
            state.company = company
            storage.setObject('company', state.company)
        },
    
        // Setando qual é o item do menu que foi acionado
        setNav (state, nav) {
            storage.set('nav', nav)
            state.nav = nav
        }
    },
    actions: {
        login (context, user) {
            return Login(user.username, user.password).then(response => {
                if (response && response.status) {
                    context.commit('setAuthenticated', response.results.authenticated)
                    context.commit('setToken', response.results.accessToken)
                    context.commit('setUser', response.results.user)
                    context.commit('setCompany', response.results.company)
                    context.commit('setNav', 'app.dashboard')
                }
                return response
            })
        },
        logout () {
            store.state.authenticated = false
            store.state.token = null
            store.state.user = null
            store.state.company = null
            store.state.nav = null
            storage.remove('authenticated')
            storage.remove('token')
            storage.remove('user')
            storage.remove('company')
            storage.remove('nav')
        },
        refreshToken (context) {
            return RefreshToken().then(response => {
                if (response && response.status) {
                    context.commit('setToken', response.results.accessToken)
                }
                return response
            })
        },
        updateUser (context, user) {
            context.commit('setUser', user)
        },
        updateCompany (context, company) {
            context.commit('setCompany', company)
        },
        nav (context, nav) {
            context.commit('setNav', nav)
        }
    }
})

export default store
