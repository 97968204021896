<template>
  <div class="app-structure" v-loading="loading.status" :element-loading-text="loading.message">
      <Unauthenticated v-if="!isAuthenticated" />
      <Authenticated v-if="isAuthenticated" />
  </div>
</template>

<script>
  import Unauthenticated from './layout/Unauthenticated.vue'
  import Authenticated from './layout/Authenticated.vue'
  
  import { ElNotification } from 'element-plus'

  export default {
      name: 'app',

      components: {
          Unauthenticated,
          Authenticated
      },

      data () {
            return {
              loading: {
                status: false,
                message: ''
              }
            }
        },

      computed: {
        isAuthenticated () {
            return this.$store.state.authenticated
        }
      },

      mounted  () {

        this.emitter.on('notify', ({ type, message }) => {
            ElNotification({
                title: (type === 'success' ? 'Sucesso' : 'Atenção'),
                message,
                type,
            })  
        })

        this.emitter.on('loading', ({ status, message }) => {
          this.loading = { status, message }
        })

      }
  }
</script>


<style lang="stylus">

  @import './assets/stylus/bootstrap.styl'

</style>
