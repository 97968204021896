import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'

import './../../security/interceptors'

const findAll = () => {
    return api.get('/companieschannels')
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar a listagem de canais')
        })
}

const find = (id) => {
    return api.get(`/companieschannels/${id}`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível carregar o canal')
        })
}

export default {
    findAll,
    find
}
