<template>
    <div class="send-month box-shadow" v-loading="loading">
        <div class="box-title">
            Envios <span class="primary">{{ formatTotal(total_send) }}</span> | Respostas <span class="primary">{{ formatTotal(total_received) }}</span> | TTS <span class="primary">{{ formatTotal(total_tts) }}</span>
        </div>
        <div class="box-height">
            <GChart
                v-if="(chartData.length > 0)"
                type="ColumnChart"
                :data="chartData"
                :options="chartOptions"
            />
        </div>
        <div class="box-footer box-filter">
            <select v-model="month">
                <option v-for="(item, key) in 12" :key="key" :value="item">{{ item }}</option>
            </select>
            <select v-model="year">
                <option v-for="(item, key) in years" :key="key" :value="item">{{ item }}</option>
            </select>
            <button type="button" class="ui-btn-default" @click="load()">Ok</button>
        </div>
    </div>
</template>

<script setup>

    import { ref, onMounted } from 'vue'
    import DashboardServices from './../services/messages/dashboard'
    import { GChart } from 'vue-google-charts'
    import moment from 'moment'

    const loading = ref(false)
    const chartData = ref([])
    const chartOptions = ref({
        pieHole: 0.4,
        height: 300,
        legend: 'none',
        tooltip: {
            textStyle: { fontSize: 11 }
        },
        chartArea: {
            width: '86%',
            height: '86%'
        },
        hAxis: {
            gridlines: { count: 0, color: 'transparent' },
            textStyle : {
                fontSize: 9
            }
        },
        vAxis: {
            gridlines: { count: 10 },
            textStyle : {
                fontSize: 9
            }
        }
    })
    const day = ref(0)
    const month = ref(0)
    const year = ref(0)
    const years = ref([])
    const total_send = ref(0)
    const total_received = ref(0)
    const total_tts = ref(0)

    const load = async () => {
        try {
            loading.value = true
            total_send.value = 0
            total_received.value = 0
            total_tts.value = 0

            let daysInMonth = moment().set('month', month.value - 1).set('year', year.value).daysInMonth()

            let query = {
                month: month.value,
                year: year.value
            }

            let response = await DashboardServices.totalSendMonth(query)

            if (response && response.status) {

                chartData.value = []
                
                chartData.value.push(['Dia', 'Envios', { role: 'style' }])
                
                for (let index = 1; index <= daysInMonth; index++) {
                    
                    let find = response.results.find((i) => {
                        return i.day === index
                    })

                    chartData.value.push([index, parseInt(find ? find.count : 0), '#9c27b0'])

                    total_send.value += find ? find.count : 0
                }

            }

            let resultReceived = await DashboardServices.totalReceivedMonth(query)
            if (resultReceived && resultReceived.status) {
                total_received.value = resultReceived.results
            }

            let resultTTS = await DashboardServices.totalTTSMonth(query)
            if (resultTTS && resultTTS.status) {
                total_tts.value = resultTTS.results
            }

            loading.value = false
        } catch (error) {
            loading.value = false
        }
    }

    const formatTotal = (p) => {
        return p.toLocaleString('pt-BR')
    }
	

    onMounted(async () => {
        let date = new Date()

        day.value = date.getDate()
        month.value = date.getMonth() + 1
        year.value = date.getFullYear()

        for (let index = 0; index < 10; index++) {
            years.value.push(year.value - index)
        }

        await load()
    })



    // import DashboardServices from './../services/messages/dashboard'
    // import { GChart } from 'vue-google-charts'
    // import moment from 'moment'

    // export default {
    //     name: 'component-send-month',

    //     components: {
    //         GChart,
    //     },

    //     data () {
    //         return {
    //             loading: false,
    //             chartData: [],
    //             chartOptions: {
    //                 pieHole: 0.4,
    //                 height: 300,
    //                 legend: 'none',
    //                 tooltip: {
    //                     textStyle: { fontSize: 11 }
    //                 },
    //                 chartArea: {
    //                     width: '86%',
    //                     height: '86%'
    //                 },
    //                 hAxis: {
    //                     gridlines: { count: 0, color: 'transparent' },
    //                     textStyle : {
    //                         fontSize: 9
    //                     }
    //                 },
    //                 vAxis: {
    //                     gridlines: { count: 10 },
    //                     textStyle : {
    //                         fontSize: 10
    //                     }
    //                 }
    //             },
    //             day: 0,
    //             month: 0,
    //             year: 0,
    //             years: [],
    //             total_send: 0,
    //             total_received: 0,
    //             total_tts: 0
    //         }
    //     },

        
    //     async created () {
    //         let date = new Date()
    //         this.day = date.getDate()
    //         this.month = date.getMonth() + 1
    //         this.year = date.getFullYear()

    //         for (let index = 2020; index <= this.year; index++) {
    //             this.years.push(index)
    //         }

    //         if (this.isMobile()) {
    //             this.chartOptions.height = 200
    //         }

    //         await this.load()
            
    //     },


    //     methods: {
            
    //         async load () {
    //             try {
    //                 this.loading = true
    //                 this.total_send = 0
    //                 this.total_received = 0
    //                 this.total_tts = 0

    //                 let daysInMonth = moment().set('month', this.month - 1).set('year', this.year).daysInMonth()

    //                 let query = {
    //                     month: this.month,
    //                     year: this.year
    //                 }

    //                 let result = await DashboardServices.totalSendMonth(query)

    //                 if (result && result.status) {

    //                     this.chartData = []

    //                     this.chartData.push(['Dia', 'Envios', { role: 'style' }])

    //                     for (let index = 1; index <= daysInMonth; index++) {

    //                         let find = result.results.find((i) => {
    //                             return i.day === index
    //                         })

    //                         this.chartData.push([index, parseInt(find ? find.count : 0), '#9c27b0'])

    //                         this.total_send += find ? find.count : 0

    //                     }

    //                 }

    //                 let resultReceived = await DashboardServices.totalReceivedMonth(query)
    //                 if (resultReceived && resultReceived.status) {
    //                     this.total_received = resultReceived.results
    //                 }

    //                 let resultTTS = await DashboardServices.totalTTSMonth(query)
    //                 if (resultTTS && resultTTS.status) {
    //                     this.total_tts = resultTTS.results
    //                 }
    //             } catch (e) {
    //                 this.emitter.emit('notify', { type: 'error', message: e.message || 'Não foi possível carregar os dados.' })
    //             } finally {
    //                 this.loading = false
    //             }
    //         },

    //         formatTotal (total) {
    //             return total.toLocaleString('pt-BR')
    //         }
            
    //     }
    // }
</script>

<style lang="stylus" scoped>

    @import './../assets/stylus/variables.styl'

    .send-month

        .box-title
            .primary
                color $primary

        .box-height
            height 200px

        .box-filter
            select, button
                width 70px

    @media screen and (min-width: 768px)

        .send-month

            .box-height
                height 300px
        

</style>

