import api from './../../security/api'
import errorCatch from './../../helpers/errorCatch'

import './../../security/interceptors'

export default () => {
    return api.post('/login/refreshtoken', {})
        .then(response => {
            return response.data
        })
        .catch(error => {
            return errorCatch(error, 'Não foi possível gerar o novo token.')
        })
}
