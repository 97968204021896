
export default [
    {
        path: '/app',
        name: 'app.dashboard',
        components: {
            authenticated: () => import(/* webpackChunkName: "app-dashboard-admin" */ './../views/App/Dashboards/Admin.vue'),
            unauthenticated: () => import(/* webpackChunkName: "app-login" */ './../views/Auth/Login.vue')
        },
        meta: {
            auth: true,
            permition: ['S', 'A', 'C']
        }
    }
]
